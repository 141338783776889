import * as React from 'react';
import { css } from '@emotion/react';
import { Draft, ErrorAlt, Inline, Link, Stack, Text } from '@resi-media/resi-ui';
import { PLAYLIST_MAX_VIDEOS_ALLOWED } from '@studio/constants/library';
import UrlPaths from '@studio/constants/url-paths';
import { usePrefix } from '@studio/hooks';
import type { Playlists } from '@studio/types';

const DEFAULT_MAX_DISPLAY = 5;

const PlaylistsDisplay = ({
  maxDisplay = DEFAULT_MAX_DISPLAY,
  playlistDetails,
}: {
  maxDisplay?: number;
  playlistDetails?: Playlists.Get.Playlist[];
}) => {
  const { commonT, prefixNS } = usePrefix('pages:', 'events.contentDestinations');
  const [viewMore, setViewMore] = React.useState(false);

  return playlistDetails ? (
    <Stack dataTestId="playlist-items-container" overflow="hidden" widthVariant="scale">
      {playlistDetails.map((playlist, index) => {
        if (index < maxDisplay || viewMore) {
          return (
            <Inline
              key={playlist.id}
              dataTestId="playlist-item"
              justifyContent="space-between"
              overflow="hidden"
              widthVariant="scale"
            >
              <Link
                dataTestId="playlist-link"
                href={UrlPaths.MEDIA.VIEW_PLAYLIST.replace(':uuid', playlist.id)}
                rel="noopener noreferrer"
                target="_blank"
                truncate
              >
                {playlist.name}
              </Link>
              <Inline alignItems="center" flexShrink={0} ml={{ md: 'xs' }}>
                {playlist.size >= PLAYLIST_MAX_VIDEOS_ALLOWED && (
                  <Draft.Tooltip content={prefixNS('fields.web.autoArchivePlaylists.full')}>
                    <ErrorAlt
                      css={(theme) => css`
                        color: ${theme.palette.negative.main};
                        font-size: ${theme.typography.pxToRem(18)};
                      `}
                      data-testid="max-playlist-size-icon"
                    />
                  </Draft.Tooltip>
                )}
                <Text colorVariant={playlist.size >= PLAYLIST_MAX_VIDEOS_ALLOWED ? 'negative' : 'primary'}>
                  {prefixNS('fields.web.autoArchivePlaylists.video', {
                    count: playlist.size,
                  }).toLowerCase()}
                </Text>
              </Inline>
            </Inline>
          );
        } else {
          return;
        }
      })}
      {playlistDetails.length > maxDisplay && (
        <Link dataTestId="view-more-button" onClick={() => setViewMore(!viewMore)} role="button" weightVariant="bold">
          {commonT(viewMore ? 'viewLess' : 'viewMore')}
        </Link>
      )}
    </Stack>
  ) : null;
};

PlaylistsDisplay.displayName = 'PlaylistsDisplay';

export default PlaylistsDisplay;
