import * as React from 'react';
import { css } from '@emotion/react';
import { Box, Checkbox, Draft, FieldLabel, Inline, Link, Progress, Stack, Text } from '@resi-media/resi-ui';
import type { Option, Theme } from '@resi-media/resi-ui';
import { produce } from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { HiddenSection } from '@studio/components/HiddenSection';
import { InlineToFragment } from '@studio/components/InlineToFragment';
import { InvalidNotice } from '@studio/components/InvalidNotice';
import { INVALID_STATUS, MAX_DESCRIPTION_LENGTH, MAX_TITLE, SOCIAL_DESTINATION_TYPES } from '@studio/constants/social';
import { makeTitleCase } from '@studio/helpers';
import { useClient, useDestinations, usePrefix } from '@studio/hooks';
import { selectCustomer } from '@studio/store';
import type { DestinationDetails, DestinationGroups } from '@studio/types';
import { getInvalidStatus } from '../../helpers/getInvalidStatus';

type _CrosspostOptions = Map<string, DestinationGroups.Derived.CrosspostOptions[]>;

type _SectionSocialFBState = {
  crosspostCache: _CrosspostOptions;
  crosspostError?: string;
  destinationCache: Map<string, DestinationDetails[]>;
  destinationOptions: Map<
    string,
    Option & {
      data: DestinationDetails;
    }
  >;
  invalidStatus?: DestinationGroups.Derived.InvalidStatus;
  privacyOptions?: Option[];
  statusOptions: Option[];
};

const FacebookFields = () => {
  const isFetchingCrosspost = React.useRef(false);
  const { control, getValues, setValue } = useFormContext<DestinationGroups.Derived.DestinationForm>();
  const { isEdit, onCloseReset } = Draft.ModalContext.useModal<DestinationGroups.Derived.DestinationModalState>();
  const { commonT, linkT, prefixNS, PrefixTrans } = usePrefix('components:', 'destinationModal');
  const { isFetching } = useSelector(selectCustomer);
  const { socialMediaOptions, webPresetOptions } = useDestinations();

  const [socialFBState, setSocialFBState] = React.useState<_SectionSocialFBState>({
    crosspostError: undefined,
    crosspostCache: new Map(),
    destinationOptions: new Map(),
    destinationCache: new Map(),
    privacyOptions: undefined,
    invalidStatus: undefined,
    statusOptions: ['published', 'unpublished'].map((opt: string) => ({
      label: makeTitleCase(commonT(`${opt}`, { titleCase: true }), /_/),
      value: opt,
    })),
  });

  const { callApi: getFacebookCrossposts } = useClient({
    config: useClient.central.v3.socialMedia.id.destinations.id.crosspostDestinations.GET,
    params: { accountId: '', destinationId: '' },
  });

  const { callApi: getSocialMediaDestinations, isFetching: isFetchingFacebookDestinations } = useClient({
    config: useClient.central.v3.socialMedia.id.destinations.GET,
    params: { channelId: '' },
  });

  const currentFB = getValues('fb');

  const {
    append: appendCrosspost,
    fields: crossposts,
    remove: removeCrossposts,
    update: updateCrosspost,
  } = useFieldArray({
    control,
    name: 'fb.crossposts',
    keyName: 'value',
  });

  const getPrivacyOptions = React.useCallback(
    (destinationId: string) => {
      const selectedDestination = socialFBState.destinationCache
        .get(currentFB.channelId ?? '')
        ?.filter((c) => c.id === destinationId)
        ?.shift();
      return (
        selectedDestination?.privacyOptions?.map((opt: string) => ({
          label: makeTitleCase(opt, /_/),
          value: opt,
        })) ?? []
      );
    },
    [currentFB.channelId, socialFBState.destinationCache]
  );

  const setPrivacyOptions = React.useMemo(
    () => (destinationId: string) => {
      if (socialFBState.destinationOptions.size > 0) {
        const privacyOptions = getPrivacyOptions(destinationId);
        const currentData = socialFBState.destinationOptions.get(destinationId);
        setSocialFBState(
          produce((draft) => {
            draft.privacyOptions = getPrivacyOptions(destinationId);
          })
        );
        const publicOption = privacyOptions.find((v) => v.value === 'public')?.value;
        if ((currentData?.data.type === SOCIAL_DESTINATION_TYPES.FB_PAGE || !getValues('fb.privacy')) && publicOption) {
          setValue('fb.privacy', publicOption, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }
      }
    },
    [getPrivacyOptions, getValues, setValue, socialFBState.destinationOptions]
  );

  const fetchCrosspostDestinations = React.useCallback(
    async (destinationId?: string) => {
      if (!destinationId || !currentFB.channelId) return;
      // FETCH CROSSPOSTS
      try {
        isFetchingCrosspost.current = true;
        const response = await getFacebookCrossposts({}, { params: { accountId: currentFB.channelId, destinationId } });

        const createOptions = response.map((opt) => ({
          checked: false,
          value: opt.destinationId,
          label: opt.destinationName,
          data: opt,
        }));
        // PREVENT ASYNC RENDER ISSUES BY PROVIDING CURRENT STATE
        setSocialFBState((state) =>
          produce(state, (draft) => {
            draft.crosspostError = undefined;
            draft.crosspostCache.set(destinationId, createOptions);
          })
        );
        isFetchingCrosspost.current = false;
        if (isEdit) {
          const initialCrossposts = getValues('fb.crossposts');
          const updatedCrossposts = createOptions.map((opt) => ({
            ...opt,
            checked: initialCrossposts ? initialCrossposts.some((c) => c.value === opt.value && c.checked) : false,
          }));
          setValue('fb.crossposts', updatedCrossposts);
        }
      } catch (error) {
        setSocialFBState(
          produce((draft) => {
            draft.crosspostError = INVALID_STATUS.FETCH_ERROR;
          })
        );
        isFetchingCrosspost.current = false;
      }
    },
    [currentFB.channelId, getFacebookCrossposts, getValues, isEdit, setValue]
  );

  const fetchFacebookDestinations = React.useCallback(
    async (channelId?: string, destinationId?: string) => {
      if (!channelId) return;

      // PREVENT RERENDER IF CURRENT STATUS IS INVALID AND UNCHANGED
      const invalidStatus = socialMediaOptions.fb.get(channelId)?.invalidStatus;
      if (invalidStatus && invalidStatus === socialFBState.invalidStatus) {
        return;
      } else if (invalidStatus) {
        setSocialFBState(
          produce((draft) => {
            draft.destinationOptions.clear();
            draft.privacyOptions = undefined;
            draft.invalidStatus = getInvalidStatus(invalidStatus);
          })
        );
        return;
      }

      // CHECK EXISTING CACHE
      const cachedFacebookDestinations = socialFBState.destinationCache.get(channelId);
      if (cachedFacebookDestinations) {
        setSocialFBState(
          produce((draft) => {
            if (isEdit && !destinationId) {
              draft.privacyOptions = undefined;
            }
            draft.destinationOptions.clear();
            cachedFacebookDestinations.forEach((opt) =>
              draft.destinationOptions.set(opt.id, { value: opt.id, label: opt.title, data: opt })
            );
            draft.invalidStatus = undefined;
          })
        );
        return;
      }

      // FETCH DESTINATIONS
      setSocialFBState(
        produce((draft) => {
          draft.privacyOptions = undefined;
        })
      );
      try {
        const response = await getSocialMediaDestinations({}, { params: { channelId } });
        // PREVENT ASYNC RENDER ISSUES BY PROVIDING CURRENT STATE
        if (isEdit && destinationId) {
          const privacyOptions = response.reduce<Option[] | undefined>((acc, destination) => {
            return !acc?.length && destination.id === destinationId
              ? (acc = destination.privacyOptions?.map((opt: string) => ({
                  label: makeTitleCase(opt, /_/),
                  value: opt,
                })))
              : acc;
          }, []);

          setSocialFBState((state) =>
            produce(state, (draft) => {
              draft.destinationOptions.clear();
              response.forEach((opt) =>
                draft.destinationOptions.set(opt.id, { value: opt.id, label: opt.title, data: opt })
              );
              draft.destinationCache.set(channelId, response);
              draft.invalidStatus = undefined;
              draft.privacyOptions = privacyOptions;
            })
          );
          return;
        }

        setSocialFBState((state) =>
          produce(state, (draft) => {
            draft.destinationOptions.clear();
            response.forEach((opt) =>
              draft.destinationOptions.set(opt.id, { value: opt.id, label: opt.title, data: opt })
            );
            draft.destinationCache.set(channelId, response);
            draft.invalidStatus = undefined;
          })
        );
      } catch (error) {
        setSocialFBState(
          produce((draft) => {
            draft.invalidStatus = INVALID_STATUS.FETCH_ERROR;
          })
        );
      }
    },
    [
      getSocialMediaDestinations,
      isEdit,
      socialFBState.destinationCache,
      socialFBState.invalidStatus,
      socialMediaOptions.fb,
    ]
  );

  /**
   * REASONS NOT TO FETCH CROSSPOSTS
   * 1) DESTINATION TYPE OTHER THAN FB PAGES
   * 2) ALREADY HAVE CACHED VERSION
   * 3) CURRENT FETCH IS ALREADY IN PROGRESS
   */
  React.useEffect(() => {
    const destinationId = currentFB.destinationId;
    if (destinationId && !isFetchingCrosspost.current) {
      const destinationType = socialFBState.destinationOptions.get(destinationId)?.data.type;
      const cachedCrosspostOptions = socialFBState.crosspostCache.get(destinationId);
      if (cachedCrosspostOptions?.length && !crossposts.length) {
        /**
         * Append can not be called in same handler as remove
         * https://react-hook-form.com/api/usefieldarray
         * Logically append needs to happen anytime after a remove
         */
        appendCrosspost(cloneDeep(cachedCrosspostOptions));
      } else if (destinationType && destinationType === SOCIAL_DESTINATION_TYPES.FB_PAGE && !cachedCrosspostOptions) {
        fetchCrosspostDestinations(destinationId);
      }
    }
  }, [
    appendCrosspost,
    crossposts,
    currentFB.destinationId,
    fetchCrosspostDestinations,
    socialFBState.crosspostCache,
    socialFBState.destinationOptions,
  ]);

  React.useEffect(() => {
    if (!isEdit) {
      setValue('fb.publishStatus', socialFBState.statusOptions[0].value);
    } else {
      fetchFacebookDestinations(currentFB.channelId, currentFB.destinationId);
    }
  }, [
    currentFB.channelId,
    currentFB.destinationId,
    fetchFacebookDestinations,
    isEdit,
    setValue,
    socialFBState.statusOptions,
  ]);

  React.useEffect(() => {
    // NOTE: AUTO-SELECT if only one FB account
    const fbAccounts = Array.from(socialMediaOptions.fb.values());
    if (fbAccounts.length === 1 && !isEdit && !fbAccounts[0].invalidStatus) {
      const singleAccountId = fbAccounts[0].value;
      setValue('fb.channelId', singleAccountId, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      fetchFacebookDestinations(singleAccountId);
    }
  }, [fetchFacebookDestinations, isEdit, setValue, socialMediaOptions.fb]);

  const handleTryAgain = () => {
    removeCrossposts();
    fetchFacebookDestinations(currentFB.channelId);
  };

  return (
    <Stack
      css={css`
        flex-basis: 100%;
      `}
      dataTestId="facebook-fields"
      gap="l"
    >
      <Controller
        control={control}
        name="fb.channelId"
        render={({
          field: { name, onBlur, ref, value },
          fieldState: { error, invalid, isTouched },
          formState: { isSubmitted },
        }) => {
          return (
            <Stack>
              <Draft.FormField
                dataTestId="fb-account-select__field"
                error={error && prefixNS('fields.fb.errors.accountRequired')}
                fieldLabel={commonT('facebookAccount')}
                htmlFor={name}
                touched={isTouched || isSubmitted}
              >
                <Draft.Select
                  ref={ref}
                  appendToBody
                  dataTestId={name}
                  disabled={isFetching}
                  hasError={invalid && Boolean(error)}
                  inputId={name}
                  onBlur={onBlur}
                  onChange={(option) => {
                    if (option) {
                      // REMOVE CROSSPOSTS FIELDS (CHECKBOXES)
                      crossposts.length && removeCrossposts();
                      setValue(name, option.value, {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      });
                      setValue('fb', {
                        ...getValues('fb'),
                        channelId: option.value,
                        crossposts: [],
                        destinationId: undefined,
                        include: true,
                        privacy: undefined,
                        publishStatus: socialFBState.statusOptions[0].value,
                      });
                      fetchFacebookDestinations(option.value);
                    }
                  }}
                  options={Array.from(socialMediaOptions.fb.values())}
                  placeholder={commonT('selectAccount')}
                  value={(value && socialMediaOptions.fb.get(value)) || null}
                />
              </Draft.FormField>
              <InvalidNotice
                channelId={value}
                invalidStatus={socialFBState.invalidStatus}
                onCloseCallback={onCloseReset}
                tryAgainCallback={handleTryAgain}
              />
            </Stack>
          );
        }}
      />
      <Controller
        control={control}
        name="fb.destinationId"
        render={({
          field: { name, onBlur, ref, value },
          fieldState: { error, invalid, isTouched },
          formState: { isSubmitted },
        }) => {
          return (
            <Draft.FormField
              dataTestId="fb-destination-select__field"
              error={error && prefixNS('fields.fb.errors.destinationRequired')}
              fieldLabel={commonT('facebookDestination')}
              htmlFor={name}
              touched={isTouched || isSubmitted}
            >
              {isFetchingFacebookDestinations ? (
                <Inline alignItems="center" dataTestId="loading-destinations">
                  <Text variant="body2">{prefixNS('fields.fb.loadingDestinations')}</Text>
                  <Progress sizeVariant="xs" />
                </Inline>
              ) : (
                <Draft.Select
                  ref={ref}
                  appendToBody
                  dataTestId={name}
                  disabled={isFetching || !socialFBState.destinationOptions.size}
                  hasError={invalid && Boolean(error)}
                  inputId={name}
                  name={name}
                  onBlur={onBlur}
                  onChange={(option) => {
                    if (option) {
                      const currentData = socialFBState.destinationOptions.get(option.value);
                      // REMOVE CROSSPOSTS FIELDS (CHECKBOXES)
                      removeCrossposts();
                      setValue(name, option.value, {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      });
                      setValue('fb', {
                        ...getValues('fb'),
                        crossposts: [],
                        destinationId: option.value,
                        socialDestinationType: currentData?.data.type,
                      });
                      setPrivacyOptions(option.value);
                    }
                  }}
                  options={Array.from(socialFBState.destinationOptions.values())}
                  placeholder={commonT('selectDestination')}
                  value={Array.from(socialFBState.destinationOptions.values()).find((v) => v.value === value) || null}
                />
              )}
            </Draft.FormField>
          );
        }}
      />
      <InlineToFragment>
        <Controller
          control={control}
          name="fb.privacy"
          render={({
            field: { name, onBlur, ref, value },
            fieldState: { error, invalid, isTouched },
            formState: { isSubmitted },
          }) => {
            const isFbPage = currentFB.socialDestinationType === SOCIAL_DESTINATION_TYPES.FB_PAGE;
            return (
              <Draft.FormField
                dataTestId="fb-privacy-select__field"
                error={error && prefixNS('fields.privacy.required')}
                fieldLabel={prefixNS('fields.privacy.title')}
                htmlFor={name}
                touched={isTouched || isSubmitted}
              >
                <Draft.Select
                  ref={ref}
                  appendToBody
                  dataTestId={name}
                  disabled={isFbPage || isFetching || !socialFBState.privacyOptions}
                  hasError={invalid && Boolean(error)}
                  inputId={name}
                  name={name}
                  onBlur={onBlur}
                  onChange={(option) => {
                    if (option) {
                      setValue(name, option.value, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
                    }
                  }}
                  options={socialFBState.privacyOptions || []}
                  placeholder={commonT('selectPrivacy')}
                  value={socialFBState.privacyOptions?.find((v) => v.value === value) || null}
                />
              </Draft.FormField>
            );
          }}
        />
        <Controller
          control={control}
          name="fb.publishStatus"
          render={({ field: { name, onBlur, ref, value }, fieldState: { isTouched }, formState: { isSubmitted } }) => (
            <Draft.FormField
              fieldLabel={prefixNS('fields.publishStatus.title')}
              hint={prefixNS('fields.publishStatus.help')}
              htmlFor={name}
              touched={isTouched || isSubmitted}
            >
              <Draft.Select
                ref={ref}
                appendToBody
                dataTestId={name}
                disabled={isFetching}
                inputId={name}
                onBlur={onBlur}
                onChange={(option) =>
                  option && setValue(name, option.value, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
                }
                options={socialFBState.statusOptions}
                value={socialFBState.statusOptions.find((v) => v.value === value)}
              />
            </Draft.FormField>
          )}
        />
      </InlineToFragment>

      <HiddenSection
        dataTestId="fb-advanced-settings"
        labelWhenHidden={commonT('viewAdvancedSettings')}
        labelWhenVisible={commonT('hideAdvancedSettings')}
      >
        {socialFBState.crosspostError ? (
          <Draft.AlertBanner
            buttonLabel={commonT('retry')}
            buttonProps={{
              onClick: () => fetchCrosspostDestinations(currentFB.destinationId),
            }}
            dataTestId="error-crossposts"
            variant="warning"
          >
            <Text variant="body3">{prefixNS('fields.fb.errors.crossposts')}</Text>
          </Draft.AlertBanner>
        ) : (
          crossposts.length > 0 && (
            <Box dataTestId="crossposts-container">
              <FieldLabel fieldLabel={prefixNS('fields.fb.crosspostTo')} />
              <Stack
                css={(theme: Theme) => css`
                  margin-top: ${theme.spacing.s};
                `}
              >
                {crossposts.map((crosspost, index) => {
                  const isSelected = crossposts.some((v) => v.value === crosspost.value && v.checked);
                  return (
                    <Checkbox
                      key={crosspost.value}
                      checked={isSelected}
                      dataTestId="crosspost-checkbox"
                      endNode={crosspost.label}
                      name={`fb.crossposts.${index}`}
                      onChange={() => {
                        updateCrosspost(index, {
                          ...crosspost,
                          checked: !isSelected, // reverse selection
                        });
                      }}
                    />
                  );
                })}
                <Draft.AlertBanner variant="info">
                  <PrefixTrans i18nKey="fields.fb.crosspostInfo">
                    <Text variant="body3">{prefixNS('fields.fb.crosspostInfo')}</Text>
                    <Link href={linkT('streamToFacebook')} rel="noopener noreferrer" target="_blank" variant="body3" />
                  </PrefixTrans>
                </Draft.AlertBanner>
              </Stack>
            </Box>
          )
        )}
        <Controller
          control={control}
          name="fb.webEncoderProfileId"
          render={({
            field: { name, onBlur, onChange, ref, value },
            fieldState: { isTouched },
            formState: { isSubmitted },
          }) => {
            return (
              <Draft.FormField
                fieldLabel={commonT('webPreset')}
                hint={prefixNS('fields.webPreset.tooltip')}
                hintPosition="top-end"
                htmlFor={name}
                touched={isTouched || isSubmitted}
              >
                <Draft.Select<Option>
                  ref={ref}
                  appendToBody
                  dataTestId={name}
                  onBlur={onBlur}
                  onChange={(option) => option && onChange(option.value)}
                  options={webPresetOptions}
                  placeholder={commonT('selectPreset')}
                  value={webPresetOptions.find((v) => v.value === value)}
                />
              </Draft.FormField>
            );
          }}
        />
        <Controller
          control={control}
          name="fb.title"
          render={({
            field: { name, onBlur, onChange, ref, value },
            fieldState: { error, invalid, isTouched },
            formState: { isSubmitted },
          }) => {
            const remainingCharacters = value?.length ? MAX_TITLE - value.length : MAX_TITLE;
            return (
              <Draft.FormField
                dataTestId="fb-title__field"
                error={error && prefixNS('fields.fb.errors.titleRequired')}
                fieldLabel={commonT('facebookVideoTitle')}
                helpText={commonT('charactersRemaining', { count: remainingCharacters })}
                htmlFor={name}
                touched={isTouched || isSubmitted}
              >
                <Draft.TextInput
                  ref={ref}
                  dataTestId={name}
                  hasError={invalid && Boolean(error)}
                  id={name}
                  maxLength={MAX_TITLE}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={commonT('videoTitle')}
                  value={value || ''}
                />
              </Draft.FormField>
            );
          }}
        />
        <Controller
          control={control}
          name="fb.description"
          render={({
            field: { name, onBlur, onChange, ref, value },
            fieldState: { error, invalid, isTouched },
            formState: { isSubmitted },
          }) => {
            const remainingCharacters = value?.length ? MAX_DESCRIPTION_LENGTH - value.length : MAX_DESCRIPTION_LENGTH;
            return (
              <Draft.FormField
                dataTestId="fb-description__field"
                error={error && prefixNS('fields.fb.errors.descriptionMatch')}
                fieldLabel={commonT('facebookVideoDescription')}
                helpText={commonT('charactersRemaining', { count: remainingCharacters })}
                htmlFor={name}
                optionalText={commonT('optional')}
                touched={isTouched || isSubmitted}
              >
                <Draft.TextInput
                  ref={ref}
                  as="textarea"
                  dataTestId={name}
                  hasError={invalid && Boolean(error)}
                  id={name}
                  maxLength={MAX_DESCRIPTION_LENGTH}
                  onBlur={onBlur}
                  onChange={onChange}
                  resize="vertical"
                  value={value || ''}
                />
              </Draft.FormField>
            );
          }}
        />
      </HiddenSection>
    </Stack>
  );
};

FacebookFields.displayName = 'FacebookFields';

export default FacebookFields;
