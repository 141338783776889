import { createAction, createAsyncAction } from 'typesafe-actions';
import type { ContentDestinations, FetchOptions } from '@studio/types';
import type { TransitioningContentDestinationFetchOptions } from './types';
import { ActionTypes } from './types';

export const fetchContentDestinationsAsync = createAsyncAction(
  ActionTypes.FETCH_REQUEST,
  ActionTypes.FETCH_REQUEST_SUCCESS,
  ActionTypes.FETCH_REQUEST_ERROR,
  ActionTypes.FETCH_REQUEST_CANCEL
)<FetchOptions | undefined, ContentDestinations.Get.ContentDestination[], Error, undefined>();

export const fetchTransitioningContentDestinationsAsync = createAsyncAction(
  ActionTypes.FETCH_REQUEST_TRANSITIONING_ONLY,
  ActionTypes.FETCH_REQUEST_TRANSITIONING_ONLY_SUCCESS,
  ActionTypes.FETCH_REQUEST_TRANSITIONING_ONLY_ERROR
)<TransitioningContentDestinationFetchOptions, ContentDestinations.Get.ContentDestination[], Error>();

// allows us to update a single content destination within the list saved in our store
export const updateSingleContentDestination = createAction(
  ActionTypes.UPDATE_SINGLE_ITEM
)<ContentDestinations.Get.ContentDestination>();

export const deleteContentDestination = createAction(ActionTypes.DELETE_ITEM)<string>();

export const setSortOrder = createAction(ActionTypes.SORT_RESULTS)<string>();

// allows us to keep track of videos the user has manually stopped
export const addStoppedContentDestination = createAction(ActionTypes.ADD_STOPPED_ITEM)<string>();
