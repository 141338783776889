import * as React from 'react';
import {
  CalendarOutlined,
  DashboardOutlined,
  FolderOutlined,
  LineChartOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import { Inline, List, ListItem, RLogo, SecurityShield, Tab, TabGroup } from '@resi-media/resi-ui';
import copy from 'copy-to-clipboard';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { NavCategory } from '@studio/constants/nav-constants';
import UrlPaths from '@studio/constants/url-paths';
import { useAdmin } from '@studio/contexts/admin';
import NavContext, { NAV_ACTION } from '@studio/contexts/nav';
import { getCurrentNavCategory, getLibraryDefaultPath } from '@studio/helpers';
import { useIsAuthorized, usePrefix } from '@studio/hooks';
import Permissions from '@studio/store/authentication/permissions';
import { selectCustomerData } from '@studio/store/customer';
import { HideOnTablet, HideOnDesktop } from '@studio/styles/utilities';
import { AdminActionMenu } from '../AdminActionMenu';
import { Authorized } from '../Authorized';
import { GoLiveButton } from '../GoLiveButton';
import { PopupMenu } from '../PopupMenu';
import { ProfilePopup } from '../ProfilePopup';
import { AppLogo, hamburgerButtonStyles, Header, IconAnchor, IconWrap } from './styles';

const AppHeader = () => {
  const { commonT, linkT, prefixNS } = usePrefix(`navigation:`);
  const location = useLocation();
  const currentNavCategory = getCurrentNavCategory(location.pathname);
  const { dispatch: contextDispatch, state } = React.useContext(NavContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const customer = useSelector(selectCustomerData);
  const canShowWebVideos = useIsAuthorized([Permissions.WEB_EVENTS_GET], false);
  const canShowEncoderVideos = useIsAuthorized([Permissions.EVENTS_GET], false);
  const { isResiAdmin, showAdminView } = useAdmin();
  const canAccessOrgDetails = useIsAuthorized([Permissions.ORGANIZATION_DETAILS_GET]);

  const settingsLandingsPageRoute = canAccessOrgDetails
    ? UrlPaths.SETTINGS.ORGANIZATION_DETAILS
    : UrlPaths.SETTINGS.ACCOUNT;

  const handleClick = (
    event: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (event.currentTarget === anchorRef.current) {
      event.stopPropagation();
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openNewTab = (url: string): void => {
    window.open(url, '_blank');
  };

  return (
    <Header data-testid="app-header">
      <Inline alignItems="center">
        <AppLogo>
          <RLogo color={theme.palette.brand.resiRed} />
        </AppLogo>
        <HideOnTablet display="flex">
          <TabGroup index={Object.keys(NavCategory).indexOf(`${currentNavCategory}`)} sizeVariant="m">
            <Tab as={Link} data-testid="dashboard-tab" startNode={<DashboardOutlined />} to={UrlPaths.DASHBOARD.ROOT}>
              {prefixNS('dashboard')}
            </Tab>
            <Tab
              as={Link}
              data-testid="media-tab"
              startNode={<FolderOutlined />}
              to={getLibraryDefaultPath(customer, canShowWebVideos, canShowEncoderVideos)}
            >
              {prefixNS('media')}
            </Tab>
            <Authorized requiredPerms={[Permissions.SCHEDULES_GET]}>
              <Tab as={Link} data-testid="schedule-tab" startNode={<CalendarOutlined />} to={UrlPaths.SCHEDULE.ROOT}>
                {prefixNS('schedule')}
              </Tab>
            </Authorized>
            <Tab as={Link} data-testid="analytics-tab" startNode={<LineChartOutlined />} to={UrlPaths.ANALYTICS.ROOT}>
              {prefixNS('analytics')}
            </Tab>
            <Tab as={Link} data-testid="settings-tab" startNode={<SettingOutlined />} to={settingsLandingsPageRoute}>
              {prefixNS('settings')}
            </Tab>
            {showAdminView && (
              <Tab as={Link} dataTestId="admin-tab" startNode={<SecurityShield />} to={UrlPaths.ADMIN.ORGANIZATIONS}>
                {prefixNS('admin')}
              </Tab>
            )}
          </TabGroup>
        </HideOnTablet>
      </Inline>
      <Inline alignItems="center">
        <HideOnDesktop>
          <Inline alignItems="center" gap="xl">
            <GoLiveButton dataTestId="go-live-button-mobile" sizeVariant="s" />
            <Inline
              dataTestId="app-header__btn--menu"
              onClick={() => contextDispatch({ type: NAV_ACTION.TOGGLE_HAMBURGER_MENU })}
              style={{ cursor: 'pointer', height: '12px', marginRight: theme.spacing.l }}
            >
              <div css={hamburgerButtonStyles(theme, state.isHamburgerMenuOpen)} data-testid="app-header__hamburger">
                <MenuOutlined style={{ color: theme.palette.text.title }} />
              </div>
            </Inline>
          </Inline>
        </HideOnDesktop>
        <HideOnTablet>
          <Inline alignItems="center" gap="m">
            <GoLiveButton dataTestId="go-live-button-desktop" />
            <IconWrap isActiveTab={open}>
              <span
                data-trackid="studio-tutorial-trigger"
                style={{ height: '1px', width: '1px', position: 'absolute' }}
              ></span>
              <IconAnchor ref={anchorRef} data-testid="help-icon" onClick={handleClick}>
                <QuestionCircleOutlined style={{ fontSize: theme.typography.pxToRem(22) }} />
              </IconAnchor>
            </IconWrap>
            <PopupMenu
              anchorEl={anchorEl}
              closeOnClick
              closeOnClickOutside
              dataTestId="app-header__list"
              isOpen={open}
              onClose={() => setAnchorEl(null)}
              placement="bottom-end"
            >
              <List index={-1}>
                <ListItem dataTestId="app-header__list--support" onClick={() => openNewTab(linkT('supportPage'))}>
                  {prefixNS('contactSupport')}
                </ListItem>
                <ListItem dataTestId="app-header__list--help" onClick={() => openNewTab(linkT('helpDocs'))}>
                  {prefixNS('helpCenter')}
                </ListItem>
                <ListItem data-trackid="studio-feedback-trigger">{prefixNS('giveFeedback')}</ListItem>
                <ListItem dataTestId="app-header__list--status" onClick={() => openNewTab(linkT('statusPage'))}>
                  {commonT('status')}
                </ListItem>
                <ListItem data-trackid="studio-tutorial-trigger">{prefixNS('guides')}</ListItem>
                <ListItem
                  colorVariant="secondary"
                  css={css`
                    cursor: text;
                  `}
                  dataTestId="app-header__list--version"
                  onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                    copy(`${import.meta.env.VITE_APP_VERSION}`);
                    e.nativeEvent.stopImmediatePropagation();
                  }}
                >
                  {import.meta.env.VITE_APP_VERSION}
                </ListItem>
              </List>
            </PopupMenu>
            {/* <IconWrap isLastOnRow>
              <NotificationBell notifications={[]} />
            </IconWrap> */}
            <ProfilePopup shouldCloseProfilePopup={open} />
          </Inline>
        </HideOnTablet>
      </Inline>
      {isResiAdmin && <AdminActionMenu />}
    </Header>
  );
};

AppHeader.displayName = 'AppHeader';

export default AppHeader;
