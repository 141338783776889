import * as React from 'react';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import { Draft, Inline, Progress, Stack, Text } from '@resi-media/resi-ui';
import { produce } from 'immer';
import { uploadSocialMediaThumbnail } from '@studio/api';
import { getCroppedImg, getFileSource, isFileValid } from '@studio/helpers';
import { useClient, usePrefix } from '@studio/hooks';
import type { UploadPOSTUrls } from '@studio/types';
import { FileDropzone } from '../FileDropzone';
import type { CropArea } from '../ImageCropper';
import { ImageCropper } from '../ImageCropper';
import { S } from './styles';

type _Props = {
  imageUrl?: string;
  onActiveFileSelect?: (isFileSelected: boolean) => void;
  onImageRemove?: () => void;
  onUploadComplete?: (imageUrl: string) => void;
};

type _State = {
  cropAreaPixels: CropArea;
  file?: File;
  fileSrc: string;
  uploadProgress: number;
  uploadUrls?: UploadPOSTUrls;
};

const initialThumbnailUploadState = {
  file: undefined,
  fileSrc: '',
  cropAreaPixels: {} as CropArea,
  uploadProgress: 0,
  uploadUrls: undefined,
};

const Thumbnail = ({ imageUrl, onActiveFileSelect, onImageRemove, onUploadComplete }: _Props) => {
  const { commonT, prefixNS } = usePrefix('components:', 'thumbnail');
  const [isLoading, setIsLoading] = React.useState(false);
  const [showFileDropZone, setShowFileDropZone] = React.useState(false);
  const [showImage, setShowImage] = React.useState(false);
  const [tnUploadState, setTnUploadState] = React.useState<_State>(initialThumbnailUploadState);
  const theme = useTheme();
  const { callApi: fetchSocialMediaUploadUrls } = useClient({
    config: useClient.central.v3.socialMedia.fileUpload.POST,
  });

  const handleImageUrlLoadFail = () => {
    setShowImage(false);
    console.warn('Failed to load thumbnail url');
  };

  const handleOnClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setShowFileDropZone(true);
  }, []);

  const handleCancel = () => {
    setTnUploadState((state) =>
      produce(state, (draft) => {
        draft.file = initialThumbnailUploadState.file;
        draft.fileSrc = initialThumbnailUploadState.fileSrc;
      })
    );
    setShowFileDropZone(false);
  };

  const handleFilesAdded = async (files: File[]) => {
    try {
      const file = files[0];
      if (isFileValid(file)) {
        const fileSrc = await getFileSource(file);

        if (!fileSrc) {
          throw new Error('Failed to read file src');
        }
        setTnUploadState((state) =>
          produce(state, (draft) => {
            draft.file = file;
            draft.fileSrc = fileSrc;
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCropComplete = React.useCallback((cropArea: CropArea) => {
    setTnUploadState((state) =>
      produce(state, (draft) => {
        draft.cropAreaPixels = cropArea;
      })
    );
  }, []);

  const handleFileUpload = React.useCallback(async () => {
    if (!tnUploadState.fileSrc) {
      return;
    }

    try {
      setIsLoading(true);
      const uploadUrlsResponse = await fetchSocialMediaUploadUrls({ extension: 'jpg' });
      const croppedImageUrl = await getCroppedImg(tnUploadState.fileSrc, tnUploadState.cropAreaPixels);

      if (croppedImageUrl) {
        await uploadSocialMediaThumbnail(uploadUrlsResponse.uploadUrl, croppedImageUrl);
        URL.revokeObjectURL(croppedImageUrl);
        if (onUploadComplete) {
          onUploadComplete(uploadUrlsResponse.downloadUrl);
        }
        setTnUploadState(initialThumbnailUploadState);
        setShowFileDropZone(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [fetchSocialMediaUploadUrls, onUploadComplete, tnUploadState.cropAreaPixels, tnUploadState.fileSrc]);

  const handleImageRemove = () => {
    if (onImageRemove) {
      onImageRemove();
    }
  };

  React.useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.onerror = handleImageUrlLoadFail;
      img.onabort = handleImageUrlLoadFail;
      img.onload = () => setShowImage(true);
      img.src = imageUrl;
    }
  }, [imageUrl]);

  const renderImageCropper = React.useCallback(() => {
    return (
      <Stack>
        <Inline justifyContent="space-between">
          <Text>{prefixNS('cropYourImage')}</Text>
          <Inline gap="m">
            <Draft.Button label={prefixNS('cancelUpload')} onClick={handleCancel} sizeVariant="s" variant="text" />
            <Draft.Button
              dataTestId="crop-and-complete-button"
              disabled={isLoading}
              label={prefixNS('cropAndComplete')}
              onClick={handleFileUpload}
              sizeVariant="s"
              {...(isLoading && { startNode: <Progress colorVariant="inherit" sizeVariant="inherit" /> })}
            />
          </Inline>
        </Inline>
        <div
          css={css`
            position: relative;
            width: 100%;
            height: 400px;
          `}
        >
          <ImageCropper aspect={16 / 9} fileSrc={tnUploadState.fileSrc} onCropComplete={handleCropComplete} />
        </div>
        <Text textAlign="center" variant="label">
          {prefixNS('clickAndDrag')}
        </Text>
      </Stack>
    );
  }, [handleCropComplete, handleFileUpload, isLoading, prefixNS, tnUploadState.fileSrc]);

  React.useEffect(() => {
    if (onActiveFileSelect) {
      onActiveFileSelect(Boolean(tnUploadState.fileSrc));
    }
  }, [onActiveFileSelect, tnUploadState.fileSrc]);

  return (
    <div>
      {showFileDropZone &&
        (tnUploadState.fileSrc ? (
          renderImageCropper()
        ) : (
          <FileDropzone isLoading={isLoading} onCancel={handleCancel} onFilesAdded={handleFilesAdded} />
        ))}
      {!showFileDropZone && (
        <Inline gap="m">
          <Draft.ButtonBase css={S.uploadButton} dataTestId="thumbnail-upload-button" onClick={handleOnClick}>
            <Stack>
              <UploadOutlined style={{ fontSize: '25px', color: theme.palette.text.secondary }} />
              <Text variant="label">{imageUrl ? commonT('replaceThumbnail') : commonT('uploadThumbnail')}</Text>
            </Stack>
          </Draft.ButtonBase>
          {imageUrl && (
            <Draft.ButtonBase css={S.thumbnailButton} dataTestId="thumbnail-button" onClick={handleImageRemove}>
              {showImage ? (
                <img alt={commonT('thumbnail')} css={S.thumbnailImage} src={imageUrl} />
              ) : (
                <div css={S.thumbnailPlaceholder} />
              )}
              <div css={S.removeThumbnailMask}>
                <DeleteOutlined style={{ fontSize: '25px', color: `${theme.palette.negative.main}` }} />
              </div>
            </Draft.ButtonBase>
          )}
        </Inline>
      )}
    </div>
  );
};

Thumbnail.displayName = 'Thumbnail';

export default Thumbnail;
