export const GOOGLE_API_KEYS = {
  MAPS: 'AIzaSyAylM-vDvz4o2SbwHjI4GaaSUhZNYSqKtY',
  YOUTUBE_DATA: 'AIzaSyBQHGUsMWHWxrtaNIrYnhSD4Rgdt4dwFq8',
};

export const YOUTUBE_SCOPE = 'https://www.googleapis.com/auth/youtube';
export const FACEBOOK_SCOPES =
  'publish_video,pages_manage_posts,read_insights,pages_show_list,pages_manage_metadata,pages_read_engagement,pages_read_user_content,pages_manage_engagement,business_management';
export const FACEBOOK_OAUTH_URL = 'https://www.facebook.com/v18.0/dialog/oauth';
export const FACEBOOK_OAUTH_REDIRECT = '/settings/social-media/fb-redirect';

export const FB_STATE_FOR_NEW_ACCT = 'addFBAuthCodeRedirect';
export const FB_STATE_FOR_EXISTING_ACCT = 'updateFBAuthCodeRedirect';

export const PROD_FACEBOOK_APP_ID = '363573127593481';
export const TEST_FACEBOOK_APP_ID = '410452363511781';

export const TEST_YOUTUBE_CLIENT_ID = '526066391594-l392j3vgcrbhlkjv67v7qnpcut3q41if.apps.googleusercontent.com';
export const PROD_YOUTUBE_CLIENT_ID = '526066391594-mrhc2863mm1uarsq368auq13erl5pl9n.apps.googleusercontent.com';

const LD_CLIENT_ID_INT = '60f982a432beb9244b10e7ab';
const LD_CLIENT_ID_PROD = '60f982a432beb9244b10e7ac';
const LD_ANONYMOUS_USER_ID_INT = '75918d5d-2f53-478e-8ee6-abb0213537fa';
const LD_ANONYMOUS_USER_ID_PROD = '53eefe85-9a92-4416-930e-ff1d263feda8';

const SEGMENT_CLIENT_ID_INT = 'ZhTMMVOeFHnf7eT8FFYMxJThjH7T0WWH';
const SEGMENT_CLIENT_ID_PROD = 's6OI9jtxj8v6USHhyTurbBIZK9fDhV9B';

export const SENTRY_ENV_LOCAL_DEV = 'local-dev';
const SENTRY_ENV_INT = 'integration';
const SENTRY_ENV_PROD = 'production';

export const RESI_ORG_ID = '6dcebe46-41c1-49f9-a318-8081d14394e3';

const ONE_HUNDRED_PERCENT = 1.0;
const TWENTY_PERCENT = 0.2;

export const STALE_DATA_TIMEOUT = 120000; // 2mins

let facebookAppId = PROD_FACEBOOK_APP_ID;
let youTubeClientId = PROD_YOUTUBE_CLIENT_ID;
let ldClientId = LD_CLIENT_ID_PROD;
let ldAnonymousUserId = LD_ANONYMOUS_USER_ID_PROD;
let segmentClientId = SEGMENT_CLIENT_ID_PROD;
let sentryEnvironment = SENTRY_ENV_PROD;
let sentrySampleRate = TWENTY_PERCENT;

switch (true) {
  case /[-_a-zA-Z0-9]*.dev-studio.pages.dev/.test(window.location.host):
  case /studio.resi-dev.us/.test(window.location.host):
  case /int-studio.resi.io/.test(window.location.host):
    youTubeClientId = TEST_YOUTUBE_CLIENT_ID;
    ldClientId = LD_CLIENT_ID_INT;
    ldAnonymousUserId = LD_ANONYMOUS_USER_ID_INT;
    segmentClientId = SEGMENT_CLIENT_ID_INT;
    sentryEnvironment = SENTRY_ENV_INT;
    sentrySampleRate = ONE_HUNDRED_PERCENT;
    break;
  case /localhost:3000/.test(window.location.host):
  case /127.0.0.1:3000/.test(window.location.host):
    facebookAppId = TEST_FACEBOOK_APP_ID;
    youTubeClientId = TEST_YOUTUBE_CLIENT_ID;
    ldClientId = LD_CLIENT_ID_INT;
    ldAnonymousUserId = LD_ANONYMOUS_USER_ID_INT;
    segmentClientId = SEGMENT_CLIENT_ID_INT;
    sentryEnvironment = SENTRY_ENV_LOCAL_DEV;
    break;
  case /^dev-studio.pages.dev/.test(window.location.host):
    segmentClientId = SEGMENT_CLIENT_ID_INT;
    sentryEnvironment = SENTRY_ENV_INT;
    sentrySampleRate = ONE_HUNDRED_PERCENT;
    break;
}

export const LD_ANONYMOUS_CONTEXT = { anonymous: true, key: ldAnonymousUserId, kind: 'user' };

export { facebookAppId, youTubeClientId, ldClientId, segmentClientId, sentryEnvironment, sentrySampleRate };
