import * as React from 'react';
import { LockOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { Draft, Inline, ToggleInput, Text, Stack, PopoutAlt } from '@resi-media/resi-ui';
import type { Option } from '@resi-media/resi-ui';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ErrorBlock } from '@studio/components/ErrorBlock';
import { HiddenSection } from '@studio/components/HiddenSection';
import { LibraryTrialModal } from '@studio/components/LibraryTrialModal';
import { NoSelectionDisplay } from '@studio/components/NoSelectionDisplay';
import { PlaylistSelection } from '@studio/components/PlaylistSelection';
import { MAX_DESCRIPTION_LENGTH } from '@studio/constants/social';
import UrlPaths from '@studio/constants/url-paths';
import { useDestinations, useLibraryTrialAccess, usePrefix, useScrollIntoView } from '@studio/hooks';
import type { DestinationGroups, Library } from '@studio/types';
import { VALIDATION } from '../../constants';

type _Props = {
  isPlaylistToggled?: boolean;
};
const WebFields = ({ isPlaylistToggled = false }: _Props) => {
  const { commonT, linkT, prefixNS, Trans } = usePrefix('components:', 'destinationModal');
  const theme = useTheme();
  const { control, setValue } = useFormContext<DestinationGroups.Derived.DestinationForm>();
  const hasLibraryTrialAccess = useLibraryTrialAccess();

  const [showNudge, setShowNudge] = React.useState(false);
  const [playlistToggle, setPlaylistToggle] = React.useState(isPlaylistToggled);
  const autosaveToggle = useWatch({ control, name: 'web.autoArchive' });

  const { isEdit, onCloseReset } = Draft.ModalContext.useModal<DestinationGroups.Derived.DestinationModalState>();

  // NOTE: allows automatic scroll down when autosave is toggled and then playlists is toggled
  const playlistToggleRef = useScrollIntoView<HTMLInputElement>(Boolean(autosaveToggle) && !playlistToggle);
  const playlistRef = useScrollIntoView<HTMLDivElement>(playlistToggle);
  const { webChannelOptions, webPresetOptions } = useDestinations();
  const hasWebChannels = Boolean(webChannelOptions.length);

  const noWebChannelOption = {
    label: prefixNS('fields.webChannel.errors.noWebChannel'),
    value: '',
  };

  React.useEffect(() => {
    // NOTE: auto-select if only one option
    if (webChannelOptions.length === 1 && !isEdit) {
      setValue('web.webEventProfileId', webChannelOptions[0].value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [isEdit, setValue, webChannelOptions]);

  const { setModal: setLibraryTrialModal, setModalState: setLibraryTrialModalState } =
    Draft.ModalContext.useModal<Library.Components.TrialModalState>();

  const handleOpenTrialModal = () => {
    setLibraryTrialModalState({ showIntro: true });
    setLibraryTrialModal(() => <LibraryTrialModal />, {
      dataTestId: 'library-trial-modal',
      minHVariant: 's',
    });
  };

  return (
    <React.Fragment>
      <Controller
        control={control}
        name="web.webEventProfileId"
        render={({
          field: { name, onBlur, ref, value },
          fieldState: { error, isTouched },
          formState: { isSubmitted },
        }) => {
          const inUseErrorMessage = error?.type === VALIDATION.IN_USE && prefixNS('inUse');
          const requiredErrorMessage =
            error?.type === VALIDATION.REQUIRED && prefixNS('fields.webChannel.errors.required.channel');
          return (
            <React.Fragment>
              <ErrorBlock
                customErrorMessage={prefixNS('fields.webChannel.errors.duplicate')}
                error={inUseErrorMessage}
              />
              <Draft.FormField
                dataTestId="web-select-field"
                error={
                  requiredErrorMessage ||
                  inUseErrorMessage ||
                  (!hasWebChannels && (
                    <NoSelectionDisplay
                      linkText={prefixNS('fields.webChannel.add')}
                      onClose={onCloseReset}
                      urlPath={UrlPaths.SETTINGS.WEB_CHANNELS_CREATE_PANE}
                      warningText={prefixNS('fields.webChannel.errors.noWebChannel')}
                    />
                  ))
                }
                fieldLabel={commonT('webChannel')}
                htmlFor={name}
                touched={isTouched || isSubmitted || !hasWebChannels}
              >
                <Draft.Select<Option>
                  ref={ref}
                  appendToBody
                  dataTestId={name}
                  disabled={!hasWebChannels}
                  hasError={Boolean(requiredErrorMessage || inUseErrorMessage)}
                  onBlur={onBlur}
                  onChange={(option) => {
                    if (option) {
                      setValue('web.webEventProfileId', option.value, { shouldValidate: true });
                    }
                  }}
                  options={webChannelOptions}
                  placeholder={commonT('selectChannel')}
                  value={
                    !hasWebChannels
                      ? noWebChannelOption
                      : Array.from(webChannelOptions.values()).find((v) => v.value === value)
                  }
                />
              </Draft.FormField>
            </React.Fragment>
          );
        }}
      />
      <HiddenSection
        labelWhenHidden={commonT('viewAdvancedSettings')}
        labelWhenVisible={commonT('hideAdvancedSettings')}
      >
        <Controller
          control={control}
          name="web.webEncoderProfileId"
          render={({
            field: { name, onBlur, onChange, ref, value },
            fieldState: { isTouched },
            formState: { isSubmitted },
          }) => {
            return (
              <Draft.FormField
                fieldLabel={commonT('webPreset')}
                hint={prefixNS('fields.webPreset.tooltip')}
                hintPosition="top-end"
                htmlFor={name}
                touched={isTouched || isSubmitted}
              >
                <Draft.Select<Option>
                  ref={ref}
                  appendToBody
                  dataTestId="web.webEncoderProfileId"
                  onBlur={onBlur}
                  onChange={(option) => option && onChange(option.value)}
                  options={webPresetOptions}
                  placeholder={commonT('selectPreset')}
                  value={webPresetOptions.find((v) => v.value === value)}
                />
              </Draft.FormField>
            );
          }}
        />
        <Controller
          control={control}
          name="web.title"
          render={({
            field: { name, onBlur, onChange, ref, value },
            fieldState: { error, isTouched },
            formState: { isSubmitted },
          }) => {
            return (
              <Draft.FormField
                dataTestId="web-video-title-field"
                error={error && prefixNS('fields.webChannel.errors.required.videoTitle')}
                fieldLabel={commonT('webVideoTitle')}
                htmlFor="name"
                touched={isTouched || isSubmitted}
              >
                <Draft.TextInput
                  ref={ref}
                  data-testid={name}
                  hasError={Boolean(error?.message)}
                  id="name"
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              </Draft.FormField>
            );
          }}
        />
        <Controller
          control={control}
          name="web.description"
          render={({ field: { name, onBlur, onChange, ref, value } }) => {
            return (
              <Draft.FormField
                fieldLabel={commonT('webVideoDescription')}
                helpText={
                  <span data-testid="web-channel-description">
                    {commonT('charactersRemaining', {
                      count: value ? MAX_DESCRIPTION_LENGTH - value.length : MAX_DESCRIPTION_LENGTH,
                    })}
                  </span>
                }
                htmlFor={name}
                optionalText={commonT('optional')}
              >
                <Draft.TextInput
                  ref={ref}
                  as="textarea"
                  dataTestId={name}
                  maxLength={MAX_DESCRIPTION_LENGTH}
                  onBlur={onBlur}
                  onChange={onChange}
                  resize="vertical"
                  rows={6}
                  value={value}
                />
              </Draft.FormField>
            );
          }}
        />
        <Stack gap="unset">
          <Controller
            control={control}
            name="web.autoArchive"
            render={({ field: { onChange, ref, value } }) => {
              return (
                <Inline
                  dataTestId="autoArchive-toggle-container"
                  {...(hasLibraryTrialAccess && { onClick: () => setShowNudge(!showNudge), cursor: 'pointer' })}
                >
                  <ToggleInput
                    ref={ref}
                    checked={value}
                    dataTestId="autoArchive-toggle"
                    dataTrackId="autosave-web-add"
                    disabled={hasLibraryTrialAccess}
                    endNode={
                      <Inline alignItems="center">
                        <Text>{commonT('autosaveToLibrary')}</Text>
                        {hasLibraryTrialAccess && (
                          <Draft.Tooltip content={commonT('noPlanTooltip')}>
                            <LockOutlined data-testid="lock-icon" style={{ color: theme.palette.primary.main }} />
                          </Draft.Tooltip>
                        )}
                      </Inline>
                    }
                    onChange={() => {
                      if (value) {
                        setPlaylistToggle(false);
                        setValue('web.autoArchivePlaylists', []);
                      }
                      onChange(!value);
                    }}
                  />
                </Inline>
              );
            }}
          />
          {autosaveToggle && (
            <React.Fragment>
              <ToggleInput
                ref={playlistToggleRef}
                checked={playlistToggle}
                dataTestId="autosave-playlist-toggle"
                dataTrackId="autosave-playlist-toggle"
                endNode={commonT('autoAddToPlaylists')}
                onChange={() => {
                  if (playlistToggle) {
                    setValue('web.autoArchivePlaylists', []);
                  }
                  setPlaylistToggle(!playlistToggle);
                }}
              />
              {playlistToggle && (
                <div ref={playlistRef}>
                  <Controller
                    control={control}
                    name="web.autoArchivePlaylists"
                    render={({ field: { onChange, value: playlistIds } }) => {
                      return (
                        <Stack dataTestId="playlist-selection-container">
                          <PlaylistSelection
                            includeBorder
                            onChange={onChange}
                            playlistIds={playlistIds ?? []}
                            scrollableTarget="add-destination-modal"
                          />
                        </Stack>
                      );
                    }}
                  />
                </div>
              )}
            </React.Fragment>
          )}
          {showNudge && (
            <Draft.AlertBanner
              buttonLabel={commonT('startFreeTrial')}
              buttonProps={{
                dataTestId: 'start-trial-button',
                variant: 'contained',
                ml: 'l',
                onClick: handleOpenTrialModal,
              }}
              dataTestId="content-library-nudge"
              scrollIntoView
              secondaryButtonLabel={commonT('learnMore')}
              secondaryButtonProps={{
                endNode: <PopoutAlt />,
                onClick: () => window.open(linkT('libraryFeaturePage')),
                dataTestId: 'learn-more-button',
                role: 'link',
              }}
              variant="info"
            >
              <Stack>
                <Trans i18nKey="pages:library.noPlan.nudge">
                  <Text>translation 0</Text>
                  <Text>translation 1</Text>
                  <Text>translation 2</Text>
                </Trans>
              </Stack>
            </Draft.AlertBanner>
          )}
        </Stack>
      </HiddenSection>
    </React.Fragment>
  );
};

WebFields.displayName = 'WebFields';

export default WebFields;
