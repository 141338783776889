import * as React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Draft, Inline, Progress, Stack, Text } from '@resi-media/resi-ui';
import { useDispatch } from 'react-redux';
import UrlPaths from '@studio/constants/url-paths';
import { usePrefix } from '@studio/hooks';
import { library, useTypedSelector } from '@studio/store';
import type { Media } from '@studio/types';
import { InfiniteScrollPaged } from '../InfiniteScrollPaged';
import { LibraryVideoRow } from './components';
import { isMediaCompleted } from './helpers';

type _Props = {
  handleContinue: (selectedVideo?: Media.Get.LibraryItem) => void;
};

export type LibraryVideoModalState = {
  startDate: Date;
  video: Media.Get.LibraryItem;
};

const LibraryVideoModal = ({ handleContinue }: _Props) => {
  const { commonT, prefixNS } = usePrefix('pages:', 'events');
  const dispatch = useDispatch();
  const { onCloseReset } = Draft.ModalContext.useModal<LibraryVideoModalState>();
  const { error, isFetching, listInfo, sortBy } = useTypedSelector((state) => state.libraryItems);
  const [selectedVideo, setSelectedVideo] = React.useState<Media.Get.LibraryItem>();
  const videoList = listInfo.videos?.filter((v) => isMediaCompleted(v)) ?? [];
  const sortOptions = [
    { label: commonT('sort.mostRecent'), value: library.SortOptions.MOST_RECENT },
    { label: commonT('sort.oldest'), value: library.SortOptions.OLDEST },
    { label: commonT('sort.alpha'), value: library.SortOptions.NAME_AtoZ },
    { label: commonT('sort.alphaReverse'), value: library.SortOptions.NAME_ZtoA },
  ];

  React.useEffect(() => {
    dispatch({ type: library.ActionTypes.FETCH_REQUEST, payload: { sort: sortBy } });
  }, [dispatch, sortBy]);

  return (
    <>
      <Draft.ModalHeader header={prefixNS('selectLibraryVideo')} onClose={onCloseReset} showBorder />
      <Draft.ModalBody dataTestId="library-video-modal-body" id="library-video-modal">
        {isFetching ? (
          <Stack dataTestId="video-modal-spinner" justifyContent="center" style={{ height: '525px' }}>
            <Progress />
          </Stack>
        ) : (
          <Stack dataTestId="video-list-container" gap="l" widthVariant="scale">
            <Inline alignItems="flex-start" justifyContent="space-between">
              <Text>{prefixNS('selectVideoFromLibrary')}</Text>
              <Draft.Select
                customCss={{
                  container: css`
                    width: 160px;
                  `,
                }}
                onChange={(option) => {
                  if (option) {
                    dispatch({ type: library.ActionTypes.SORT_RESULTS, payload: option.value });
                    dispatch({ type: library.ActionTypes.FETCH_REQUEST_TIMESTAMP, payload: 0 });
                  }
                }}
                options={sortOptions}
                value={sortOptions.find((v) => v.value === sortBy)}
              />
            </Inline>
            <Stack gap="unset" widthVariant="scale">
              {videoList.length > 0 ? (
                <InfiniteScrollPaged
                  error={error}
                  getMore={() => {
                    dispatch({ type: library.ActionTypes.FETCH_MORE_REQUEST });
                  }}
                  hasMore={() => Boolean(listInfo.nextUrl)}
                  items={videoList}
                  renderList={(data) =>
                    data.map((vid) => (
                      <LibraryVideoRow key={vid.mediaId} onSelectVideo={(v) => setSelectedVideo(v)} video={vid} />
                    ))
                  }
                  scrollableTarget="library-video-modal"
                />
              ) : (
                <Stack dataTestId="no-video-container" gap="xl">
                  <Text variant="body3" weightVariant="bold">
                    {prefixNS('noVideosUpload')}
                  </Text>
                  <div>
                    <Draft.Button
                      as="a"
                      dataTestId="upload-video-button"
                      href={UrlPaths.MEDIA.UPLOAD_VIDEO}
                      label={commonT('uploadVideo')}
                      startNode={<PlusOutlined />}
                      target="_blank"
                    />
                  </div>
                </Stack>
              )}
            </Stack>
          </Stack>
        )}
      </Draft.ModalBody>
      <Draft.ModalFooter>
        <Inline>
          <Draft.Button
            dataTrackId="cancel-select-library-video"
            label={commonT('cancel')}
            onClick={onCloseReset}
            variant="text"
          />
          <Draft.Button
            dataTestId="continue-button"
            disabled={!selectedVideo}
            label={commonT('continue')}
            onClick={() => {
              handleContinue(selectedVideo);
              onCloseReset();
            }}
          />
        </Inline>
      </Draft.ModalFooter>
    </>
  );
};

LibraryVideoModal.displayName = 'LibraryVideoModal';

export default LibraryVideoModal;
