import * as React from 'react';
import { useTheme } from '@emotion/react';
import {
  Stack,
  Draft,
  IconButton,
  Inline,
  PopoutAlt,
  Link as RUILink,
  RadioGroup,
  Radio,
  ToggleInput,
} from '@resi-media/resi-ui';
import { useDispatch, useSelector } from 'react-redux';
import { webplayerEnvAttribute, WEBPLAYER_SOURCE } from '@studio/constants/env-variables';
import { DISPLAY_MODE } from '@studio/constants/player-profiles';
import { buildQueryString, parseTimeIntoSeconds } from '@studio/helpers';
import { useClient, usePrefix } from '@studio/hooks';
import { playerProfiles as playerProfilesStore, selectCustomerId } from '@studio/store';
import { ConfirmationModal } from '../ConfirmationModal';
import { CopyToClipboard } from '../CopyToClipboard';
import { EmbedOptions } from '../EmbedOptions';
import { EmbedTypeFieldLabel } from '../EmbedTypeFieldLabel';
import { ErrorBlock } from '../ErrorBlock';
import { EmbedType } from '../WebPlayer';
import { EMBED_MODAL_ACTIONS, SHARE_TYPE } from './constants';
import { embedModalReducer, initialState } from './reducer';
import type { EmbedModalAction, EmbedModalState, ShareType } from './types';

type _State = {
  contentDestinationScheduleId?: string | null;
  dashUrl?: string;
  embedId: string;
  embedType: EmbedType | null;
  eventStartDateTime?: Date;
  hlsUrl?: string;
  isWebDest?: boolean;
  mediaId?: string;
  title: string;
};

const computeCompositeProfileEmbedId = (
  selectedPlayerProfile: string | undefined,
  customerId: string | undefined
): string | undefined => {
  const isDefault = selectedPlayerProfile === DISPLAY_MODE.DEFAULT;
  if (!selectedPlayerProfile || !customerId || isDefault) {
    return undefined;
  }
  return window.btoa(`${customerId}:${selectedPlayerProfile}`);
};

const EmbedModal = (): React.JSX.Element => {
  const { commonT, prefixNS, t } = usePrefix('pages:', 'library');
  const theme = useTheme();
  const mounted = React.useRef(false);
  const dispatch = useDispatch();
  const {
    contentDestinationScheduleId,
    dashUrl,
    embedId,
    embedType,
    eventStartDateTime,
    hlsUrl,
    isWebDest,
    mediaId,
    onCloseReset,
    title,
  } = Draft.ModalContext.useModal<_State>();
  const customerId = useSelector(selectCustomerId);
  const [state, stateDispatch] = React.useReducer<React.Reducer<EmbedModalState, EmbedModalAction>>(
    embedModalReducer,
    initialState
  );
  const profileOptions = useSelector(playerProfilesStore.selectProfileOptions());
  const isSelectedProfileInBackgroundMode =
    state.selectedPlayerProfile === DISPLAY_MODE.BACKGROUND && Boolean(state.selectedPlayerProfile);
  const singleEventStartTime = state.isSingleEvent && eventStartDateTime ? eventStartDateTime.toISOString() : undefined;
  const playerProfileEmbedId = computeCompositeProfileEmbedId(state.selectedPlayerProfile, customerId);

  const getCueOptions = useClient({
    config: useClient.cues.v1.media.id.cues.GET,
    params: { mediaId: mediaId ?? '' },
  });

  // decode embedId to prevent re-encoding of encoded characters
  const decodedEmbedId = decodeURIComponent(embedId);

  const isAutoplayDisabled =
    !playerProfileEmbedId && (embedType === EmbedType.LIBRARY || embedType === EmbedType.PLAYLIST);
  const startPosSeconds = parseTimeIntoSeconds(state.startPosition);
  const startPosParam = startPosSeconds > 0 ? startPosSeconds.toString() : '';
  const standAlonePlayerCode = `${WEBPLAYER_SOURCE}?${buildQueryString({
    id: decodedEmbedId,
    playerProfileId: isSelectedProfileInBackgroundMode ? null : playerProfileEmbedId,
    background: isSelectedProfileInBackgroundMode ? 'true' : null,
    type: embedType === EmbedType.WEB_CHANNEL ? null : embedType,
    autoplay: isAutoplayDisabled ? 'false' : null,
    startPos: startPosParam,
    env: webplayerEnvAttribute,
  })}`;

  const webDestStandalonePlayerUrl = `${WEBPLAYER_SOURCE}?${buildQueryString({
    id: decodedEmbedId,
    playerProfileId: isSelectedProfileInBackgroundMode ? null : playerProfileEmbedId,
    background: isSelectedProfileInBackgroundMode ? 'true' : null,
    startPos: startPosParam,
    env: webplayerEnvAttribute,
  })}`;

  const playerProfileOptions = [{ label: commonT('default'), value: DISPLAY_MODE.DEFAULT }, ...profileOptions];

  const standAlonePlayerCodeUrl = isWebDest ? webDestStandalonePlayerUrl : standAlonePlayerCode;

  const eventLink = `${WEBPLAYER_SOURCE}?${buildQueryString({
    type: 'schedule',
    id: contentDestinationScheduleId,
    playerProfileId: isSelectedProfileInBackgroundMode ? null : playerProfileEmbedId,
    background: isSelectedProfileInBackgroundMode ? 'true' : null,
    startTime: singleEventStartTime,
    env: webplayerEnvAttribute,
  })}`;

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (!mounted.current) {
      mediaId && getCueOptions.callApi();
      dispatch({ type: playerProfilesStore.ActionTypes.FETCH_REQUEST });
      mounted.current = true;
    }
  }, [dispatch, mediaId, getCueOptions]);

  const defaultCueOption = [{ label: commonT('startOfVideo'), value: commonT('startOfVideo') }];
  const cueOptions = defaultCueOption.concat(
    getCueOptions.data?.map((option) => ({
      label: `${option.name} - ${option.position}`,
      value: option.position,
    })) ?? []
  );

  return (
    <ConfirmationModal handleClose={onCloseReset} isOpen isUsingContext title={title} variant="info">
      <ErrorBlock dataTestId="cueOptions-error-block" error={getCueOptions.error} />
      <Stack gap="l">
        <Inline>
          {/* `> 1` to ensure that there are more options available beyond the provided Default option */}
          {playerProfileOptions.length > 1 && (
            <Draft.FormField
              dataTestId="player-profile-selection"
              fieldLabel={t('pages:playerProfiles.title')}
              htmlFor="player-profiles-title"
            >
              <Draft.Select
                appendToBody
                dataTestId="profile-selection-input"
                defaultValue={playerProfileOptions[0]}
                inputId="player-profile-id"
                onChange={(option) =>
                  option && stateDispatch({ type: EMBED_MODAL_ACTIONS.PLAYER_PROFILE, payload: option.value })
                }
                options={playerProfileOptions}
                value={playerProfileOptions.find((v) => v.value === state.selectedPlayerProfile)}
              />
            </Draft.FormField>
          )}
          {embedType === EmbedType.LIBRARY && (
            <Draft.FormField
              fieldLabel={prefixNS('cues.startFromCue')}
              hint={prefixNS('cues.tooltip')}
              htmlFor="cue-selection-field"
            >
              <Draft.Select
                appendToBody
                dataTestId="cue__selection--field"
                defaultValue={cueOptions[0]}
                disabled={cueOptions.length === 1}
                inputId="cue__selection--field"
                onChange={(option) => {
                  if (option) {
                    stateDispatch({ type: EMBED_MODAL_ACTIONS.CUE, payload: option.value });
                    stateDispatch({ type: EMBED_MODAL_ACTIONS.START_POSITION, payload: option.value });
                  }
                }}
                options={cueOptions}
                value={cueOptions.find((v) => v.value === state.selectedCueOption)}
              />
            </Draft.FormField>
          )}
        </Inline>
        {isWebDest && (
          <Draft.FormField
            fieldLabel={t('components:embedModal.shareType')}
            hint={t('components:embedModal.shareTypeHint')}
            hintPosition="right"
          >
            <RadioGroup
              dataTestId="radio-group__share-type"
              gap="xs"
              name="share-type"
              onChange={(type) => {
                stateDispatch({ type: EMBED_MODAL_ACTIONS.SHARE_TYPE, payload: type as ShareType });
              }}
              value={state.shareType}
            >
              <Radio
                dataTestId="radio-share"
                endNode={commonT('shareLink')}
                id={SHARE_TYPE.SHARE}
                value={SHARE_TYPE.SHARE}
              />
              <Radio
                dataTestId="radio-embed"
                endNode={commonT('embedPlayer')}
                id={SHARE_TYPE.EMBED}
                value={SHARE_TYPE.EMBED}
              />
            </RadioGroup>
          </Draft.FormField>
        )}
        {state.shareType === SHARE_TYPE.EMBED ? (
          <>
            <Inline alignItems="center" gap="l">
              <Draft.FormField fieldLabel={prefixNS('viewMedia.standalonePlayer')} htmlFor="std-iframe-embed-code">
                <Draft.TextInput
                  as="textarea"
                  data-testid="stand-alone-code"
                  id="std-iframe-embed-code"
                  readOnly
                  value={standAlonePlayerCodeUrl}
                />
              </Draft.FormField>
              <div style={{ position: 'relative', bottom: '-8px' }}>
                <IconButton
                  as={RUILink}
                  href={standAlonePlayerCodeUrl}
                  label={prefixNS('viewMedia.openExternalLink')}
                  rel="noreferrer noopener"
                  sizeVariant="s"
                  target="_blank"
                >
                  <PopoutAlt style={{ color: theme.palette.primary.main }} />
                </IconButton>
              </div>
              <div style={{ position: 'relative', bottom: '-8px' }}>
                <CopyToClipboard
                  dataTestId="std-iframe-copy-icon"
                  dataTrackId="copy-standalone-player"
                  name={prefixNS('viewMedia.standalonePlayer')}
                  text={standAlonePlayerCodeUrl}
                />
              </div>
            </Inline>
            <Stack gap="xs">
              <EmbedTypeFieldLabel hintPosition="right" />
              <EmbedOptions
                dashUrl={dashUrl}
                embedId={embedId || ''}
                hlsUrl={hlsUrl}
                isPlayerProfileInBackgroundMode={isSelectedProfileInBackgroundMode}
                playerProfileEmbedId={playerProfileEmbedId}
                startPos={startPosParam}
                type={isWebDest ? null : embedType}
              />
            </Stack>
          </>
        ) : (
          <Draft.FormField
            fieldLabel={t('components:embedModal.eventLink')}
            hint={t('components:embedModal.eventLinkHint')}
            hintPosition="right"
          >
            <Stack>
              <Inline alignItems="center">
                <Draft.TextInput
                  as="textarea"
                  data-testid="event-link"
                  dataTestId="event-link"
                  id="event-link"
                  readOnly
                  value={eventLink}
                />
                <CopyToClipboard
                  dataTestId="event-link-copy-icon"
                  name={t('components:embedModal.eventLink')}
                  text={eventLink}
                />
              </Inline>
              <ToggleInput
                checked={state.isSingleEvent}
                dataTestId="event-link-toggle"
                endNode={t('components:embedModal.eventLinkToggleText')}
                onChange={() =>
                  stateDispatch({ type: EMBED_MODAL_ACTIONS.SINGLE_EVENT, payload: !state.isSingleEvent })
                }
              />
            </Stack>
          </Draft.FormField>
        )}
      </Stack>
    </ConfirmationModal>
  );
};

EmbedModal.displayName = 'EmbedModal';

export default EmbedModal;
