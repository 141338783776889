import * as React from 'react';
import { LockOutlined } from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import type { PaletteMode } from '@resi-media/resi-ui';
import {
  Badge,
  ToggleInput,
  useBreakpoint,
  FieldLabel,
  Inline,
  LeftAlt,
  RightAlt,
  Text,
  Stack,
  useThemeConfig,
  Hr,
} from '@resi-media/resi-ui';
import copy from 'copy-to-clipboard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LAUNCHDARKLY_TOGGLES } from '@studio/constants/launchdarkly-toggle-constants';
import { useAdmin } from '@studio/contexts/admin';
import NavContext, { NAV_ACTION } from '@studio/contexts/nav';
import { getCurrentNavCategory } from '@studio/helpers';
import {
  useIsAuthorized,
  useIsVodPlanType,
  useLibraryTrialAccess,
  usePrefix,
  useRtmpAccess,
  useRtmpDiscoverability,
  useSignOut,
} from '@studio/hooks';
import { selectCustomerData } from '@studio/store';
import Permissions from '@studio/store/authentication/permissions';
import { Authorized } from '../Authorized';
import getMenuSections from './config';
import { S } from './styles';

enum HAMBURGER_MENUS {
  MAIN_MENU,
  ANALYTICS,
  SETTINGS,
  ADMIN,
  MEDIA,
  HELP,
}

const UseAdminViewCheck = ({
  children,
  requiredPerms,
}: {
  children: React.ReactNode;
  requiredPerms: readonly Permissions[];
}) => {
  const { showAdminView } = useAdmin();

  if (showAdminView || !requiredPerms.length) return children;

  if (requiredPerms.every((requiredPerms) => requiredPerms === Permissions.RESI_ADMIN_ONLY)) return null;

  return children;
};

const HamburgerMenu = () => {
  const { commonT, prefixNS, prefixT } = usePrefix(`navigation:`);
  const { dispatch, state: navState } = React.useContext(NavContext);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedRoute = location.pathname;
  const theme = useTheme();
  const { showAdminView } = useAdmin();
  const { setMode } = useThemeConfig();
  const handleSignOut = useSignOut();
  const currentNavCategory = getCurrentNavCategory(selectedRoute, true);
  const [currentMenu, setCurrentMenu] = React.useState(HAMBURGER_MENUS.MAIN_MENU);
  const mediaQuery = useBreakpoint();
  const customer = useSelector(selectCustomerData);
  const canShowWebVideos = useIsAuthorized([Permissions.WEB_EVENTS_GET], false);
  const canShowEncoderVideos = useIsAuthorized([Permissions.EVENTS_GET], false);
  const hasLibraryTrialAccess = useLibraryTrialAccess();
  const hasRtmpAccess = useRtmpAccess();
  const hasMultiSitePlan = Boolean(customer?.multisitePlanId);
  const vodPlanType = useIsVodPlanType();
  const { [LAUNCHDARKLY_TOGGLES.ACCT_SETTINGS_REDESIGN]: acctSettingRedesign } = useFlags();
  const hasRtmpDiscoverability = useRtmpDiscoverability();

  const menuSections = getMenuSections(
    customer,
    canShowWebVideos,
    canShowEncoderVideos,
    hasLibraryTrialAccess,
    hasMultiSitePlan,
    vodPlanType,
    acctSettingRedesign,
    hasRtmpAccess,
    hasRtmpDiscoverability,
    showAdminView
  );

  React.useEffect(() => {
    if (navState.isHamburgerMenuOpen && mediaQuery.xl) {
      dispatch({ type: NAV_ACTION.TOGGLE_HAMBURGER_MENU });
    }
  }, [mediaQuery, dispatch, navState.isHamburgerMenuOpen]);

  const themeMode = localStorage.getItem('studio-theme-mode') as PaletteMode | null;
  const setDarkMode = React.useCallback(() => {
    const themeToSet = themeMode === 'dark' ? 'light' : 'dark';
    setMode(themeToSet);
    localStorage.setItem('studio-theme-mode', themeToSet);
  }, [setMode, themeMode]);

  const handleOnClick = (e: React.MouseEvent, label: string, routes: string[] | null) => {
    if (label === 'settings') {
      e.stopPropagation();
      setCurrentMenu(HAMBURGER_MENUS.SETTINGS);
    } else if (label === 'analytics') {
      e.stopPropagation();
      setCurrentMenu(HAMBURGER_MENUS.ANALYTICS);
    } else if (label === 'admin') {
      e.stopPropagation();
      setCurrentMenu(HAMBURGER_MENUS.ADMIN);
    } else if (label === 'darkMode') {
      e.stopPropagation();
      setDarkMode();
    } else if (label === 'media') {
      e.stopPropagation();
      setCurrentMenu(HAMBURGER_MENUS.MEDIA);
    } else if (label === 'help') {
      e.stopPropagation();
      setCurrentMenu(HAMBURGER_MENUS.HELP);
    } else if (label === 'signOut') {
      handleSignOut();
    } else if (routes !== null) {
      navigate(routes[0]);
    }
  };

  React.useEffect(() => {
    if (!navState.isHamburgerMenuOpen) {
      setCurrentMenu(HAMBURGER_MENUS.MAIN_MENU);
    }
  }, [navState.isHamburgerMenuOpen]);

  return (
    <div css={S.overlay(theme, navState.isHamburgerMenuOpen)} data-testid="hamburger-menu-overlay">
      <div css={S.container(theme, navState.isHamburgerMenuOpen)} data-testid="hamburger-menu-container">
        {currentMenu === HAMBURGER_MENUS.MAIN_MENU &&
          menuSections.root.map((subMenuItem) => {
            return (
              <UseAdminViewCheck key={subMenuItem.label} requiredPerms={subMenuItem.requiredPerms}>
                <div>
                  {subMenuItem.label === 'help' && (
                    <div style={{ padding: `${theme.spacing.xl} .75rem` }}>
                      <Hr />
                    </div>
                  )}
                  <Authorized
                    includesAny
                    requiredPerms={subMenuItem.requiredPerms}
                    requiredToggles={subMenuItem.requiredToggles}
                  >
                    <Inline
                      alignItems="center"
                      css={S.subMenuItemContainer(theme, currentNavCategory === subMenuItem.navCategory)}
                      dataTestId={`main-menu-${subMenuItem.label}-label`}
                      justifyContent="space-between"
                      onClick={(e) => handleOnClick(e, subMenuItem.label, subMenuItem.routes)}
                    >
                      {/* Remove dark mode menu when ACCT_SETTINGS_REDESIGN LD toggle is no longer needed */}
                      {subMenuItem.label === 'darkMode' ? (
                        <>
                          <Inline alignItems="center" gap="m">
                            {subMenuItem.icon}
                            {prefixNS('darkMode')}
                          </Inline>
                          <ToggleInput
                            checked={themeMode === 'dark'}
                            dataTestId="hamburger-menu__toggle--readonly"
                            onClick={(e) => e.stopPropagation()}
                            readOnly
                            tabIndex={-1}
                          />
                        </>
                      ) : (
                        <Inline alignItems="center" gap="m">
                          {subMenuItem.icon}
                          <Text
                            colorVariant="brand"
                            {...(subMenuItem.label === 'signOut' && {
                              colorVariant: 'negative',
                            })}
                          >
                            {subMenuItem.label === 'account' || subMenuItem.label === 'signOut'
                              ? commonT(subMenuItem.label)
                              : prefixNS(subMenuItem.label)}
                          </Text>
                          {subMenuItem.badgeTextKey && <Badge>{commonT(subMenuItem.badgeTextKey)}</Badge>}
                        </Inline>
                      )}
                      {subMenuItem.isNested && (
                        <RightAlt
                          data-testid={`${subMenuItem.label}-right-alt`}
                          style={{ fontSize: theme.typography.pxToRem(14) }}
                        />
                      )}
                    </Inline>
                  </Authorized>
                </div>
              </UseAdminViewCheck>
            );
          })}
        {currentMenu === HAMBURGER_MENUS.ANALYTICS && (
          <div data-testid="analytics-submenu">
            <Inline
              alignItems="center"
              css={S.subMenuItemContainer(theme, false)}
              dataTestId="analytics-back-button"
              justifyContent="space-between"
              onClick={(e) => {
                e.stopPropagation();
                setCurrentMenu(HAMBURGER_MENUS.MAIN_MENU);
              }}
            >
              <Inline alignItems="center" gap="m">
                <LeftAlt style={{ fontSize: theme.typography.pxToRem(14) }} />
                <Text colorVariant="brand" variant="body3" weightVariant="bold">
                  {prefixNS('analytics')}
                </Text>
              </Inline>
            </Inline>
            {menuSections.analytics.map((section, k) => {
              return (
                <UseAdminViewCheck key={`analytics-section-${k}`} requiredPerms={section.requiredPerms}>
                  <div css={S.subMenuItemContainer(theme, false, false)} style={{ paddingLeft: theme.spacing.l }}>
                    {section.sections.map((s) => {
                      return (
                        <UseAdminViewCheck key={s.label} requiredPerms={s.requiredPerms}>
                          <Authorized includesAny requiredPerms={s.requiredPerms} requiredToggles={s.requiredToggles}>
                            <Inline
                              alignItems="center"
                              css={S.subMenuItemContainer(theme, s.routes.includes(selectedRoute))}
                              dataTestId={`analytics-section--${s.label}`}
                              gap="m"
                              justifyContent="space-between"
                              onClick={(e) => handleOnClick(e, s.label, s.routes)}
                            >
                              {s.icon}
                              <Text
                                colorVariant="brand"
                                css={css`
                                  flex: 1 1 100%;
                                `}
                                variant="body3"
                                weightVariant="regular"
                              >
                                {prefixT(s.label)}
                              </Text>
                            </Inline>
                          </Authorized>
                        </UseAdminViewCheck>
                      );
                    })}
                  </div>
                </UseAdminViewCheck>
              );
            })}
          </div>
        )}
        {currentMenu === HAMBURGER_MENUS.MEDIA && (
          <div data-testid="media-submenu">
            <Inline
              alignItems="center"
              css={S.subMenuItemContainer(theme, false)}
              dataTestId="media-back-button"
              justifyContent="space-between"
              onClick={(e) => {
                e.stopPropagation();
                setCurrentMenu(HAMBURGER_MENUS.MAIN_MENU);
              }}
            >
              <Inline alignItems="center" gap="m">
                <LeftAlt style={{ fontSize: theme.typography.pxToRem(14) }} />
                <Text colorVariant="brand" variant="body3" weightVariant="bold">
                  {commonT('media')}
                </Text>
              </Inline>
            </Inline>
            <Stack gap="m">
              {menuSections.media.map((section, k) => {
                return (
                  <UseAdminViewCheck
                    key={`media-section-${k}-${section.sectionCategory}`}
                    requiredPerms={section.requiredPerms}
                  >
                    <div css={S.subMenuItemContainer(theme, false, false)} style={{ paddingLeft: theme.spacing.l }}>
                      <Stack>
                        <Authorized includesAny requiredPerms={section.requiredPerms}>
                          <FieldLabel
                            fieldLabel={commonT(section.sectionCategory)}
                            style={{ color: theme.palette.text.secondary }}
                          />
                        </Authorized>
                        {section.sections.map((s) => {
                          return (
                            <UseAdminViewCheck key={s.label} requiredPerms={s.requiredPerms}>
                              <Authorized
                                includesAny
                                requiredPerms={s.requiredPerms}
                                requiredToggles={s.requiredToggles}
                              >
                                <Inline
                                  alignItems="center"
                                  css={S.subMenuItemContainer(theme, s.routes.includes(selectedRoute))}
                                  dataTestId={`media-section--${s.label}`}
                                  justifyContent="space-between"
                                  onClick={(e) => handleOnClick(e, s.label, s.routes)}
                                >
                                  <Inline alignItems="center" gap="m">
                                    {s.icon}
                                    <Text colorVariant="brand" variant="body3" weightVariant="regular">
                                      {prefixT(s.label)}
                                    </Text>
                                    {s.isGated && (
                                      <LockOutlined
                                        css={(theme) => ({
                                          fontSize: theme.typography.pxToRem(16),
                                          color: theme.palette.primary.main,
                                        })}
                                        data-testid={`${s.label}__icon--gated`}
                                      />
                                    )}
                                  </Inline>
                                </Inline>
                              </Authorized>
                            </UseAdminViewCheck>
                          );
                        })}
                      </Stack>
                    </div>
                  </UseAdminViewCheck>
                );
              })}
            </Stack>
          </div>
        )}
        {currentMenu === HAMBURGER_MENUS.SETTINGS && (
          <div data-testid="settings-submenu">
            <Inline
              alignItems="center"
              css={S.subMenuItemContainer(theme, false)}
              dataTestId="settings-back-button"
              justifyContent="space-between"
              onClick={(e) => {
                e.stopPropagation();
                setCurrentMenu(HAMBURGER_MENUS.MAIN_MENU);
              }}
            >
              <Inline alignItems="center" gap="m">
                <LeftAlt style={{ fontSize: theme.typography.pxToRem(14) }} />
                <Text colorVariant="brand" variant="body3" weightVariant="bold">
                  {prefixNS('settings')}
                </Text>
              </Inline>
            </Inline>
            <Stack gap="m">
              {menuSections.settings.map((section, i) => {
                return (
                  <UseAdminViewCheck
                    key={`settings-section-${i}-${section.sectionCategory}`}
                    requiredPerms={section.requiredPerms}
                  >
                    <div css={S.subMenuItemContainer(theme, false, false)} style={{ paddingLeft: theme.spacing.l }}>
                      <Stack>
                        <Authorized includesAny requiredPerms={section.requiredPerms}>
                          <FieldLabel
                            fieldLabel={commonT(section.sectionCategory)}
                            style={{ color: theme.palette.text.secondary }}
                          />
                        </Authorized>
                        {section.sections.map((s) => {
                          return (
                            <UseAdminViewCheck key={s.label} requiredPerms={s.requiredPerms}>
                              <Authorized
                                includesAny
                                requiredPerms={s.requiredPerms}
                                requiredToggles={s.requiredToggles}
                                requiredTogglesIncludesAny={s.requiredTogglesIncludesAny}
                              >
                                <div>
                                  <Inline
                                    alignItems="center"
                                    css={S.subMenuItemContainer(theme, s.routes.includes(selectedRoute))}
                                    dataTestId={`settings-section--${s.label}`}
                                    justifyContent="space-between"
                                    onClick={(e) => handleOnClick(e, s.label, s.routes)}
                                  >
                                    <Inline alignItems="center" gap="m">
                                      <Text colorVariant="brand" variant="body3" weightVariant="regular">
                                        {prefixT(s.label)}
                                      </Text>
                                      {s.icon}
                                    </Inline>
                                  </Inline>
                                </div>
                              </Authorized>
                            </UseAdminViewCheck>
                          );
                        })}
                      </Stack>
                    </div>
                  </UseAdminViewCheck>
                );
              })}
            </Stack>
          </div>
        )}
        {currentMenu === HAMBURGER_MENUS.ADMIN && (
          <div data-testid="admin-submenu">
            <Inline
              alignItems="center"
              css={S.subMenuItemContainer(theme, false)}
              dataTestId="admin-back-button"
              justifyContent="space-between"
              onClick={(e) => {
                e.stopPropagation();
                setCurrentMenu(HAMBURGER_MENUS.MAIN_MENU);
              }}
            >
              <Inline alignItems="center" gap="m">
                <LeftAlt style={{ fontSize: theme.typography.pxToRem(14) }} />
                <Text colorVariant="brand" variant="body3" weightVariant="bold">
                  {prefixNS('admin')}
                </Text>
              </Inline>
            </Inline>
            <Stack gap="m">
              {menuSections.admin.map((section, k) => {
                return (
                  <UseAdminViewCheck
                    key={`admin-section-${k}-${section.sectionCategory}`}
                    requiredPerms={section.requiredPerms}
                  >
                    <div css={S.subMenuItemContainer(theme, false, false)} style={{ paddingLeft: theme.spacing.l }}>
                      <Stack>
                        <Authorized includesAny requiredPerms={section.requiredPerms}>
                          <FieldLabel
                            fieldLabel={commonT(section.sectionCategory ?? '')}
                            style={{ color: theme.palette.text.secondary }}
                          />
                        </Authorized>
                        {section.sections.map((s) => {
                          return (
                            <UseAdminViewCheck key={s.label} requiredPerms={s.requiredPerms}>
                              <Authorized
                                includesAny
                                requiredPerms={s.requiredPerms}
                                requiredToggles={s.requiredToggles}
                              >
                                <div>
                                  <Inline
                                    alignItems="center"
                                    css={S.subMenuItemContainer(theme, s.routes.includes(selectedRoute))}
                                    dataTestId={`admin-section--${s.label}`}
                                    justifyContent="space-between"
                                    onClick={(e) => handleOnClick(e, s.label, s.routes)}
                                  >
                                    <Inline alignItems="center" gap="m">
                                      <Text colorVariant="brand" variant="body3" weightVariant="regular">
                                        {prefixT(s.label)}
                                      </Text>
                                    </Inline>
                                  </Inline>
                                </div>
                              </Authorized>
                            </UseAdminViewCheck>
                          );
                        })}
                      </Stack>
                    </div>
                  </UseAdminViewCheck>
                );
              })}
            </Stack>
          </div>
        )}
        {currentMenu === HAMBURGER_MENUS.HELP && (
          <div data-testid="help-submenu">
            <Inline
              alignItems="center"
              css={S.subMenuItemContainer(theme, false)}
              dataTestId="help-back-button"
              justifyContent="space-between"
              onClick={(e) => {
                e.stopPropagation();
                setCurrentMenu(HAMBURGER_MENUS.MAIN_MENU);
              }}
            >
              <Inline alignItems="center" gap="m">
                <LeftAlt style={{ fontSize: theme.typography.pxToRem(14) }} />
                <Text colorVariant="brand" variant="body3" weightVariant="bold">
                  {prefixNS('help')}
                </Text>
              </Inline>
            </Inline>
            <div style={{ padding: `${theme.spacing.s} ${theme.spacing.xl}` }}>
              {menuSections.help.map((subMenuItem) => (
                <Inline
                  key={subMenuItem.label}
                  alignItems="center"
                  css={S.subMenuItemContainer(theme, false)}
                  dataTestId={`help-section--${subMenuItem.label}`}
                  justifyContent="space-between"
                  onClick={() => {
                    window.open(subMenuItem.routes[0], subMenuItem.routes[1]);
                  }}
                >
                  <Inline gap="m">
                    <Text colorVariant="brand">{prefixNS(subMenuItem.label)}</Text>
                  </Inline>
                </Inline>
              ))}
              <Inline
                alignItems="center"
                css={css`
                  cursor: text;
                  ${theme.mixins.insetSquish('s')(theme)}
                `}
                dataTestId="help-section--version"
                justifyContent="space-between"
                onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                  copy(`${import.meta.env.VITE_APP_VERSION}`);
                  e.nativeEvent.stopImmediatePropagation();
                  e.stopPropagation();
                }}
              >
                <Text as="span" colorVariant="secondary">
                  {import.meta.env.VITE_APP_VERSION}
                </Text>
              </Inline>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

HamburgerMenu.displayName = 'HamburgerMenu';

export default HamburgerMenu;
