export const MAX_DESCRIPTION_LENGTH = 5_000;
export const MAX_TITLE_LENGTH = 128;
export const RELOAD_CUES_TIME = 10000;
export const PLAYLIST_MAX_VIDEOS_ALLOWED = 100;
export const VOD_TRIAL_PLAN_ID = 'vod-starter-with-trial-yearly';
export const ENTERPRISE_VOD_PLAN = 'a4b53c18-d793-4de9-b479-ebb2430d6045';
export const PREMIUM_VOD_PLAN = '7ee77a28-c601-4762-bbcc-7c43120fb3e0';
export const PRO_VOD_PLAN = 'b410f580-2cc5-46db-92ab-0dcb73c7b28a';
export const STARTER_VOD_PLAN = 'd18f6e1e-243d-44fa-af9c-1b3c2401e40c';
export const PAYLOAD_EXTENSION = '.jpg';
export const PAYLOAD_PATH = '/thumbnails';

export const CONTENT_STATUS = {
  IDLE: 'IDLE',
  SET_UP: 'SET_UP',
  STARTING: 'STARTING',
  STARTED: 'STARTED',
  STOPPING: 'STOPPING',
  STOPPED: 'STOPPED',
  ABORTED: 'ABORTED',
  ERROR: 'ERROR',
} as const;

export const CUE_TYPES = {
  SAVED: 'saved',
  WEB: 'web',
  ENCODER: 'encoder',
} as const;

export const CUE_VISIBILITY = {
  PRIVATE: 'private', // can only be seen by user who created the cue
  SHARED: 'org', // can be viewed by all users
  WEB_PLAYER: 'public', // cue is visible inside webplayer
} as const;

export const EVENT_TYPE = {
  CONTENT_DESTINATION: 'contentdestinations',
  EVENT: 'events',
  LIBRARY: 'library',
  WEB_EVENT: 'webevents',
} as const;

export const MEDIA_VIEW = {
  LIST: 'LIST',
  GALLERY: 'GALLERY',
} as const;

export const LIBRARY_VIEW_KEY = 'library-view-key';

export const PLAYLISTS_VIEW_KEY = 'playlists-view-key';

export const PLAYER_VIDEO = {
  WEB: 'web-video-player',
  ENCODER: 'encoder-video-player',
  FACEBOOK: 'fb-video-player',
} as const;

export const PLAYOUT_BEHAVIOR = {
  ON_DEMAND: 'ON_DEMAND',
  ON_DEMAND_UNPUBLISHED: 'ON_DEMAND_UNPUBLISHED',
  REAL_TIME: 'REAL_TIME',
} as const;

export const SAVED_VIDEO_ERROR_REASON = {
  ARCHIVE: 'archive',
  DELETE: 'delete',
} as const;

export const SAVED_VIDEO_STATE = {
  COMPLETED: 'completed',
  CONTENT_AVAILABLE: 'content_available',
  DELETE_PENDING: 'delete_pending',
  ERROR: 'errored',
  PARTIAL_DELETE_PENDING: 'partial_delete_pending',
  REPUBLISH_PENDING: 'republish_pending',
  STARTED: 'started',
} as const;

export const START_WEB_DESTINATION_TYPES = {
  PAGE: 'fb_page',
  TIMELINE: 'fb_timeline',
  GROUP: 'fb_group',
  YOUTUBE: 'yt_create_new',
  NONE: 'NONE',
} as const;

export const TRIAL_MODAL_BODY_STATES = {
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  NO_PAYMENT_METHOD: 'NO_PAYMENT_METHOD',
  TRIAL_EXPIRED: 'TRIAL_EXPIRED',
  INVALID_CONTACT: 'INVALID_CONTACT',
  TRIAL_SIGNUP_SUSPENDED: 'TRIAL_SIGNUP_SUSPENDED',
} as const;

export const THUMBNAIL_DIMENSIONS = {
  HEIGHT: '40px',
  HEIGHT_MOBILE: '32px',
  WIDTH: '70px',
  WIDTH_MOBILE: '56px',
} as const;
