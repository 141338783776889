import * as React from 'react';
import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import type { StyleOverride, MergeElementProps, MergeFirst } from '@resi-media/resi-ui';
import { Stack, useBreakpoint, Text, getStyles, mergeDefaultProps, Box } from '@resi-media/resi-ui';
import omit from 'lodash/omit';
import { P, match } from 'ts-pattern';

const S = {
  container: (theme: Theme, props: _InternalProps): SerializedStyles => {
    return css({
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      gridTemplateAreas: [
        props.breadcrumbs && "'page-header-breadcrumbs'",
        props.titleNode && "'page-header-title'",
        props.description && "'page-header-description'",
        props.cta && "'page-header-cta'",
      ]
        .filter(Boolean)
        .join(' '),
      [theme.mq.lg]: {
        gridTemplateAreas: [
          props.breadcrumbs && "'page-header-breadcrumbs page-header-breadcrumbs'",
          match(props)
            .with({ titleNode: P.any, cta: P.any }, () => "'page-header-title page-header-cta'")
            .with({ titleNode: P.any }, () => "'page-header-title .'")
            .with({ cta: P.any }, () => "'. page-header-cta'")
            .otherwise(() => false),
          props.description && "'page-header-description page-header-description'",
        ]
          .filter(Boolean)
          .join(' '),
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
      },
    });
  },
  title: (theme: Theme): SerializedStyles =>
    css({
      gridArea: 'page-header-title',
      display: 'flex',
      flexFlow: 'row nowrap',
      columnGap: theme.spacing.m,
      alignItems: 'center',
    }),
};

type CustomCss = {
  container?: StyleOverride<_InternalProps>;
};

const defaultProps = {};

type _Props = MergeElementProps<
  'div',
  {
    breadcrumbs?: React.ReactNode;
    children?: never;
    cta?: React.ReactNode;
    customCss?: CustomCss;
    description?: React.ReactNode;
    icon?: React.ReactNode;
    titleNode?: React.ReactNode;
  }
>;

type _InternalProps = MergeFirst<_Props, typeof defaultProps>;

const ContentHeader = React.forwardRef<HTMLDivElement, _Props>((props, ref) => {
  const propsInternal: _InternalProps = mergeDefaultProps(props, defaultProps);
  const { breadcrumbs, cta, dataTestId, description, icon, titleNode, ...rest } = propsInternal;
  const mediaQuery = useBreakpoint();

  return (
    <Box
      ref={ref}
      as="header"
      css={(theme) => [S.container(theme, propsInternal), getStyles('container', theme, propsInternal)]}
      dataTestId="page-header-container"
      gap={{ xs: 'm', lg: 'l' }}
      pb={{ xs: 's', lg: 0 }}
      pt={{ xs: 'm', lg: 'l', xxl: 'xl' }}
      px={{ xs: 'm', lg: 'l', xxl: 'xl' }}
      {...omit(rest, 'customCss')}
    >
      {breadcrumbs && (
        <div css={{ gridArea: 'page-header-breadcrumbs' }} data-testid="page-header-breadcrumbs">
          {breadcrumbs}
        </div>
      )}
      {titleNode && (
        <Text as="h3" css={S.title} dataTestId="page-header-title">
          {icon}
          <span>{titleNode}</span>
        </Text>
      )}
      {description && (
        <Text
          as={React.isValidElement(description) ? 'div' : 'p'}
          colorVariant="primary"
          dataTestId="page-header-description"
          style={{ gridArea: 'page-header-description' }}
          variant="body3"
        >
          {description}
        </Text>
      )}
      {cta && (
        <Stack
          css={{ gridArea: 'page-header-cta' }}
          dataTestId="page-header-cta"
          widthVariant={mediaQuery.lg ? 'auto' : 'scale'}
        >
          {cta}
        </Stack>
      )}
    </Box>
  );
});

ContentHeader.displayName = 'ContentHeader';

export default ContentHeader;
