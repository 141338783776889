import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';

export const S = {
  uploadButton: (theme: Theme): SerializedStyles => css`
    height: 85px;
    padding: ${theme.spacing.m};
    border: 1px dashed ${theme.palette.border};
    border-radius: ${theme.shape.borderRadius.m};
    background-color ${theme.palette.background.table};
    margin-top: 2px;
    &:hover {
      border: 1px solid ${theme.palette.primary.hover};
    }
  `,
  thumbnailButton: (theme: Theme): SerializedStyles => css`
    position: relative;
    border: 2px solid;
    border-color: ${theme.palette.primary.main};
    border-radius: ${theme.shape.borderRadius.m};

    &:hover {
      border-color: ${theme.palette.negative.main};
    }
  `,
  thumbnailImage: (theme: Theme): SerializedStyles => css`
    height: 85px;
    width: 148px;
    border-radius: ${theme.shape.borderRadius.s};
  `,
  thumbnailPlaceholder: (_theme: Theme): SerializedStyles => css`
    height: 85px;
    width: 148px;
    box-sizing: border-box;
    background-color: black;
  `,
  removeThumbnailMask: (theme: Theme): SerializedStyles => css`
    position: absolute;
    display: grid;
    place-items: center;
    height: 85px;
    width: 148px;
    box-sizing: border-box;
    opacity: 0;
    transition: ${theme.transitions.create('background-color')};

    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
      transition: ${theme.transitions.create('opacity')};
    }
  `,
};
