import * as React from 'react';
import { Draft } from '@resi-media/resi-ui';
import type { Option } from '@resi-media/resi-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ErrorBlock } from '@studio/components/ErrorBlock';
import { HiddenSection } from '@studio/components/HiddenSection';
import { NoSelectionDisplay } from '@studio/components/NoSelectionDisplay';
import UrlPaths from '@studio/constants/url-paths';
import { useDestinations, usePrefix } from '@studio/hooks';
import { selectFilteredItems } from '@studio/store/rtmp-destinations';
import type { DestinationGroups } from '@studio/types';
import { VALIDATION } from '../../constants';

const RtmpFields = () => {
  const { commonT, prefixNS } = usePrefix('components:', 'destinationModal');
  const { control, setValue } = useFormContext<DestinationGroups.Derived.DestinationForm>();
  const { isEdit, onCloseReset } = Draft.ModalContext.useModal<DestinationGroups.Derived.DestinationModalState>();
  const filteredRtmpDestinations = useSelector(selectFilteredItems);

  const noRtmpOption = {
    label: prefixNS('fields.rtmp.errors.noRtmp'),
    value: '',
  };

  const { rtmpOptions, webPresetOptions } = useDestinations();
  const hasRtmpDestinations = Boolean(filteredRtmpDestinations.length);

  React.useEffect(() => {
    if (rtmpOptions.length === 1 && !isEdit) {
      setValue('rtmp.rtmpDestinationId', rtmpOptions[0].value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [setValue, rtmpOptions, isEdit]);

  return (
    <React.Fragment>
      <Controller
        control={control}
        name="rtmp.rtmpDestinationId"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error, isTouched },
          formState: { isSubmitted },
        }) => {
          const inUseErrorMessage = error?.type === VALIDATION.IN_USE && prefixNS('inUse');
          const requiredErrorMessage = error?.type === VALIDATION.REQUIRED && prefixNS('fields.rtmp.errors.required');
          return (
            <React.Fragment>
              <ErrorBlock customErrorMessage={prefixNS('fields.rtmp.errors.duplicate')} error={inUseErrorMessage} />
              <Draft.FormField
                dataTestId="rtmp-select-field"
                error={
                  requiredErrorMessage ||
                  inUseErrorMessage ||
                  (!hasRtmpDestinations && (
                    <NoSelectionDisplay
                      linkText={prefixNS('fields.rtmp.add')}
                      onClose={onCloseReset}
                      urlPath={UrlPaths.SETTINGS.RTMP_DESTINATIONS_CREATE}
                      warningText={prefixNS('fields.rtmp.errors.noRtmp')}
                    />
                  ))
                }
                fieldLabel={prefixNS('fields.rtmp.label')}
                hint={prefixNS('fields.rtmp.hint')}
                htmlFor={name}
                touched={isTouched || isSubmitted || !hasRtmpDestinations}
              >
                <Draft.Select<Option>
                  ref={ref}
                  appendToBody
                  dataTestId={name}
                  disabled={!hasRtmpDestinations}
                  hasError={Boolean(requiredErrorMessage || inUseErrorMessage)}
                  onBlur={onBlur}
                  onChange={(option) => {
                    if (option) {
                      onChange(option.value);
                    }
                  }}
                  options={rtmpOptions}
                  placeholder={commonT('selectDestination')}
                  value={!hasRtmpDestinations ? noRtmpOption : rtmpOptions.find((v) => v.value === value)}
                />
              </Draft.FormField>
            </React.Fragment>
          );
        }}
      />
      <HiddenSection
        labelWhenHidden={commonT('viewAdvancedSettings')}
        labelWhenVisible={commonT('hideAdvancedSettings')}
      >
        <Controller
          control={control}
          name="rtmp.webEncoderProfileId"
          render={({
            field: { name, onBlur, onChange, ref, value },
            fieldState: { isTouched },
            formState: { isSubmitted },
          }) => {
            return (
              <Draft.FormField
                fieldLabel={commonT('webPreset')}
                hint={prefixNS('fields.webPreset.tooltip')}
                hintPosition="top-end"
                htmlFor={name}
                touched={isTouched || isSubmitted}
              >
                <Draft.Select<Option>
                  ref={ref}
                  appendToBody
                  dataTestId={name}
                  onBlur={onBlur}
                  onChange={(option) => option && onChange(option.value)}
                  options={webPresetOptions}
                  placeholder={commonT('selectPreset')}
                  value={webPresetOptions.find((v) => v.value === value)}
                />
              </Draft.FormField>
            );
          }}
        />
      </HiddenSection>
    </React.Fragment>
  );
};

RtmpFields.displayName = 'RtmpFields';

export default RtmpFields;
