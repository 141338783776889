import * as React from 'react';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Draft, Stack, useForkRef } from '@resi-media/resi-ui';
import { zxcvbnAsync } from '@zxcvbn-ts/core';
import { Controller, useFormContext } from 'react-hook-form';
import { initZxcvbnOptions } from '@studio/helpers/zxcvbn/zxcvbn';
import { usePrefix } from '@studio/hooks';
import { PasswordStrengthBar } from '../PasswordStrengthBar';
import { useFormValidation } from './helpers/useFormValidation';

const PLACEHOLDER = '**********';

type _Props = Stack.Props & {
  confirmPasswordLabel?: string;
  hintText?: React.ReactNode;
  isTextInputDisabled?: boolean;
  passwordLabel?: string;
};

type PasswordType = 'password' | 'text';

const ConfirmPasswordInternal = (
  { confirmPasswordLabel, hintText, isTextInputDisabled, passwordLabel, ...rest }: _Props,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const videoPlayer = React.useRef<HTMLDivElement>(null);
  const handleRef = useForkRef(ref, videoPlayer);
  const { commonT, prefixNS } = usePrefix('components:', 'confirmPassword');
  const {
    control,
    formState: { dirtyFields: _dF, errors, isSubmitted: isFormSubmitted, touchedFields },
    setValue,
    trigger,
  } = useFormContext();

  const [passwordStrength, setPasswordStrength] = React.useState(0);
  const [passwordError, setPasswordError] = React.useState('');
  const [newPasswordType, setNewPasswordType] = React.useState<PasswordType>('password');
  const [confirmPasswordType, setConfirmPasswordType] = React.useState<PasswordType>('password');

  React.useEffect(() => {
    initZxcvbnOptions();
  }, []);

  const handlePasswordChange = async (password: string): Promise<void> => {
    const zxcvbnResults = await zxcvbnAsync(password);
    const passwordScore = zxcvbnResults.score;
    const newPasswordError = zxcvbnResults.feedback.warning ?? '';

    setValue('passwordError', newPasswordError);
    setValue('passwordStrength', passwordScore, { shouldValidate: true });

    if (errors.confirmPassword && (touchedFields.confirmPassword || isFormSubmitted)) {
      trigger('confirmPassword');
    }
    setPasswordStrength(passwordScore);
    setPasswordError(newPasswordError);
  };

  const toggleNewPasswordType = () => {
    setNewPasswordType(newPasswordType === 'password' ? 'text' : 'password');
  };

  const toggleConfirmPasswordType = () => {
    setConfirmPasswordType(confirmPasswordType === 'password' ? 'text' : 'password');
  };

  return (
    <Stack
      ref={handleRef}
      dataTestId="confirm-password-container"
      justifyContent="space-between"
      widthVariant="scale"
      {...rest}
    >
      <Stack>
        <Controller
          control={control}
          name="password"
          render={({ field: { name, onBlur, onChange, ref, value }, fieldState: { error, isTouched } }) => {
            return (
              <Draft.FormField
                fieldLabel={passwordLabel || commonT('password')}
                hint={hintText}
                hintPosition="top"
                htmlFor={name}
                touched={isTouched || isFormSubmitted}
              >
                <Draft.TextInput<'input'>
                  ref={ref}
                  autoComplete="new-password"
                  // Disable LastPass
                  data-lpignore="true"
                  data-testid="password"
                  disabled={isTextInputDisabled}
                  endNode={
                    newPasswordType === 'password' ? (
                      <EyeOutlined
                        alt={prefixNS('showPassword')}
                        data-testid="show-password"
                        onClick={toggleNewPasswordType}
                        title={prefixNS('showPassword')}
                      />
                    ) : (
                      <EyeInvisibleOutlined
                        alt={prefixNS('hidePassword')}
                        data-testid="hide-password"
                        onClick={toggleNewPasswordType}
                        title={prefixNS('hidePassword')}
                      />
                    )
                  }
                  hasError={(isTouched || isFormSubmitted) && Boolean(error?.message)}
                  id={name}
                  name="password"
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e);
                    handlePasswordChange(e.currentTarget.value);
                  }}
                  placeholder={PLACEHOLDER}
                  type={newPasswordType}
                  value={value}
                />
              </Draft.FormField>
            );
          }}
        />
        <Controller
          control={control}
          defaultValue={undefined}
          name="passwordStrength"
          render={() => (
            <PasswordStrengthBar
              errorMessage={String(errors.password?.message || errors.passwordStrength?.message || '')}
              helpMessage={commonT('errors.password.pleaseEnterPassword')}
              passwordStrength={passwordStrength}
            />
          )}
        />
        <Controller
          control={control}
          name="passwordError"
          render={({ field: { onChange } }) => (
            <input
              data-testid="hidden-password-error"
              name="passwordError"
              onChange={onChange}
              type="hidden"
              value={passwordError}
            />
          )}
        />
      </Stack>
      <Controller
        control={control}
        name="confirmPassword"
        render={({ field: { name, onBlur, onChange, ref, value }, fieldState: { error, isTouched } }) => {
          return (
            <Draft.FormField
              error={error?.message}
              fieldLabel={confirmPasswordLabel || prefixNS('confirm')}
              htmlFor={name}
              touched
            >
              <Draft.TextInput<'input'>
                ref={ref}
                autoComplete="new-password"
                // Disable LastPass
                data-lpignore="true"
                data-testid="confirm-password"
                disabled={isTextInputDisabled}
                endNode={
                  confirmPasswordType === 'password' ? (
                    <EyeOutlined
                      alt={prefixNS('showPassword')}
                      data-testid="show-confirm-password"
                      onClick={toggleConfirmPasswordType}
                      title={prefixNS('showPassword')}
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      alt={prefixNS('hidePassword')}
                      data-testid="hide-confirm-password"
                      onClick={toggleConfirmPasswordType}
                      title={prefixNS('hidePassword')}
                    />
                  )
                }
                hasError={(isTouched || isFormSubmitted) && Boolean(error?.message)}
                id={name}
                name="confirmPassword"
                onBlur={onBlur}
                onChange={onChange}
                placeholder={PLACEHOLDER}
                type={confirmPasswordType}
                value={value}
              />
            </Draft.FormField>
          );
        }}
      />
    </Stack>
  );
};

ConfirmPasswordInternal.displayName = 'ConfirmPasswordInternal';

const ConfirmPassword = Object.assign(React.forwardRef(ConfirmPasswordInternal), {
  displayName: 'ConfirmPassword',
  useFormValidation,
});

export default ConfirmPassword;
