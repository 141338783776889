import { NavCategory } from '@studio/constants/nav-constants';
import UrlPaths from '@studio/constants/url-paths';

function getCurrentNavCategory(path: string, isHamburgerMenu?: boolean): NavCategory | null {
  const isPathInCategory = (categoryRoot: string) => path.startsWith(categoryRoot);

  if (isPathInCategory(UrlPaths.MEDIA.ROOT)) {
    return NavCategory.MEDIA;
  }
  if (isPathInCategory(UrlPaths.SCHEDULE.ROOT)) {
    return NavCategory.SCHEDULE;
  }
  if (isPathInCategory(UrlPaths.ANALYTICS.ROOT)) {
    return NavCategory.ANALYTICS;
  }
  if (path === UrlPaths.SETTINGS.ACCOUNT && isHamburgerMenu) {
    return NavCategory.ACCOUNT;
  }
  if (isPathInCategory(UrlPaths.SETTINGS.ROOT)) {
    return NavCategory.SETTINGS;
  }
  if (isPathInCategory(UrlPaths.ADMIN.ROOT)) {
    return NavCategory.ADMIN;
  }
  if (isPathInCategory(UrlPaths.HELP.ROOT)) {
    return NavCategory.HELP;
  }
  if (isPathInCategory(UrlPaths.OPEN_SOURCE_INFO)) {
    return null;
  }
  // TODO: (j$ 9/29/2021) figure out a better way to match urls
  // that have tokens, ie. /settings/social-media/:uuid
  // Dashboard check MUST be last since the root ='/'
  if (isPathInCategory(UrlPaths.DASHBOARD.ROOT)) {
    return NavCategory.DASHBOARD;
  }
  console.warn('path is not in a navigation category');
  return null;
}

export default getCurrentNavCategory;
