export type State = {
  contacts: Contact[];
  error: Error | null;
  filter: string;
  isFetching: boolean;
  orgs: Organization[];
  searchBy: SearchBy;
};

export type Organization = {
  name: string;
  planName: string | null;
  uuid: string;
  vodPlanName: string | null;
  webPlanName: string | null;
};

export type Contact = {
  customerId: string;
  customerName: string;
  email?: string;
  first: string;
  last: string;
  mobile?: string;
  notes?: string;
  primaryContact: boolean;
  sendBillingAlerts: boolean;
  sendStreamingAlerts: boolean;
  title?: string;
  url?: string;
  userId: string;
  uuid: string;
};

export const SEARCH_BY = {
  ORG_NAME: 'ORG_NAME',
  USER_NAME: 'USER_NAME',
  PHONE_NUMBER: 'PHONE_NUMBER',
} as const;
export type SearchBy = (typeof SEARCH_BY)[keyof typeof SEARCH_BY];

export enum ActionTypes {
  CLEAR_RESULTS = '@@adminsearch/CLEAR_RESULTS',
  FETCH_REQUEST_CONTACTS = '@@contacts/FETCH_REQUEST_CONTACTS',
  FETCH_REQUEST_CONTACTS_CANCEL = '@@contacts/FETCH_REQUEST_CONTACTS_CANCEL',
  FETCH_REQUEST_CONTACTS_ERROR = '@@contacts/FETCH_REQUEST_CONTACTS_ERROR',
  FETCH_REQUEST_CONTACTS_SUCCESS = '@@contacts/FETCH_REQUEST_CONTACTS_SUCCESS',
  FETCH_REQUEST_ORGS = '@@organizations/FETCH_REQUEST_ORGS',
  FETCH_REQUEST_ORGS_CANCEL = '@@organizations/FETCH_REQUEST_ORGS_CANCEL',
  FETCH_REQUEST_ORGS_ERROR = '@@organizations/FETCH_REQUEST_ORGS_ERROR',
  FETCH_REQUEST_ORGS_SUCCESS = '@@organizations/FETCH_REQUEST_ORGS_SUCCESS',
  FILTER_RESULTS = '@@adminsearch/FILTER_RESULTS',
  SEARCH = '@@adminsearch/SEARCH',
  SEARCH_BY = '@@adminsearch/SEARCH_BY',
}
