import * as React from 'react';
import { css } from '@emotion/react';
import type { Option } from '@resi-media/resi-ui';
import { Draft, Stack } from '@resi-media/resi-ui';
import { produce } from 'immer';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { HiddenSection } from '@studio/components/HiddenSection';
import { InlineToFragment } from '@studio/components/InlineToFragment';
import { InvalidNotice } from '@studio/components/InvalidNotice';
import { INVALID_STATUS, MAX_DESCRIPTION_LENGTH, MAX_TITLE } from '@studio/constants/social';
import { makeTitleCase } from '@studio/helpers';
import { useClient, useDestinations, usePrefix } from '@studio/hooks';
import { selectCustomer } from '@studio/store';
import type { DestinationDetails, DestinationGroups } from '@studio/types';
import { VALIDATION } from '../../constants';
import { getInvalidStatus } from '../../helpers/getInvalidStatus';

type _SectionSocialYTState = {
  destinationCache: Map<string, DestinationDetails>;
  destinationOptions: Map<
    string,
    Option & {
      data: DestinationDetails;
    }
  >;
  invalidStatus?: DestinationGroups.Derived.InvalidStatus;
  privacyOptions?: Option[];
};

const YoutubeFields = () => {
  const { commonT, prefixNS } = usePrefix('components:', 'destinationModal');
  const { control, getValues, setValue } = useFormContext<DestinationGroups.Derived.DestinationForm>();
  const { isEdit, onCloseReset } = Draft.ModalContext.useModal<DestinationGroups.Derived.DestinationModalState>();
  const { socialMediaOptions, webPresetOptions } = useDestinations();
  const { isFetching } = useSelector(selectCustomer);
  const [socialYTState, setSocialYTState] = React.useState<_SectionSocialYTState>({
    destinationOptions: new Map(),
    destinationCache: new Map(),
    privacyOptions: undefined,
    invalidStatus: undefined,
  });

  const { callApi: getSocialMediaDestinations } = useClient({
    config: useClient.central.v3.socialMedia.id.destinations.GET,
    params: { channelId: '' },
  });
  const currentYT = getValues('yt');

  const fetchYoutubeAccounts = React.useCallback(
    async (channelId?: string) => {
      if (!channelId) return;

      // PREVENT RERENDER IF CURRENT STATUS IS INVALID AND UNCHANGED
      const invalidStatus = socialMediaOptions.yt.get(channelId)?.invalidStatus;
      if (invalidStatus && invalidStatus === socialYTState.invalidStatus) {
        return;
      } else if (invalidStatus) {
        setSocialYTState(
          produce((draft) => {
            draft.invalidStatus = getInvalidStatus(invalidStatus);
          })
        );
        setValue('yt.privacy', undefined);
        setSocialYTState((state) =>
          produce(state, (draft) => {
            draft.privacyOptions = undefined;
          })
        );
        return;
      }

      // CHECK EXISTING CACHE
      const cachedYoutubeAccount = socialYTState.destinationCache.get(channelId);
      if (cachedYoutubeAccount) {
        setSocialYTState(
          produce((draft) => {
            draft.privacyOptions = cachedYoutubeAccount.privacyOptions?.map((opt: string) => ({
              label: makeTitleCase(opt, /_/),
              value: opt,
            }));
            draft.invalidStatus = undefined;
          })
        );
        return;
      }

      try {
        const response = await getSocialMediaDestinations({}, { params: { channelId } });
        // PREVENT ASYNC RENDER ISSUES BY PROVIDING CURRENT STATE
        setSocialYTState((state) =>
          produce(state, (draft) => {
            draft.destinationOptions.clear();
            draft.destinationCache.set(channelId, response[0]);
            draft.privacyOptions = response[0].privacyOptions?.map((opt: string) => ({
              label: makeTitleCase(opt, /_/),
              value: opt,
            }));
            draft.invalidStatus = undefined;
          })
        );
        const publicOption = response[0].privacyOptions?.find((opt) => opt === 'public');
        if (!getValues('yt.privacy') && publicOption) {
          setValue('yt.privacy', publicOption, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }
      } catch (error) {
        setSocialYTState(
          produce((draft) => {
            draft.invalidStatus = INVALID_STATUS.FETCH_ERROR;
          })
        );
      }
    },
    [
      getSocialMediaDestinations,
      getValues,
      setValue,
      socialMediaOptions.yt,
      socialYTState.destinationCache,
      socialYTState.invalidStatus,
    ]
  );

  React.useEffect(() => {
    if (isEdit) {
      fetchYoutubeAccounts(currentYT.channelId);
    }
  }, [currentYT.channelId, fetchYoutubeAccounts, isEdit]);

  React.useEffect(() => {
    // NOTE: AUTO-SELCT if only one YT account
    const ytAccounts = Array.from(socialMediaOptions.yt.values());
    if (ytAccounts.length === 1 && !isEdit && !ytAccounts[0].invalidStatus) {
      setValue('yt.channelId', ytAccounts[0].value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue('yt.privacy', 'public', {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      fetchYoutubeAccounts(ytAccounts[0].value);
    }
  }, [fetchYoutubeAccounts, isEdit, setValue, socialMediaOptions.yt]);

  return (
    <Stack
      css={css`
        flex-basis: 100%;
      `}
      dataTestId="youtube-fields"
      gap="l"
    >
      <Controller
        control={control}
        name="yt.channelId"
        render={({
          field: { name, onBlur, ref, value },
          fieldState: { error, invalid, isTouched },
          formState: { isSubmitted },
        }) => {
          return (
            <Stack>
              <Draft.FormField
                dataTestId="yt-account-select__field"
                error={error && prefixNS('fields.yt.errors.accountRequired')}
                fieldLabel={commonT('youtubeAccount')}
                htmlFor={name}
                touched={isTouched || isSubmitted}
              >
                <Draft.Select
                  ref={ref}
                  appendToBody
                  dataTestId={name}
                  disabled={isFetching}
                  hasError={invalid && Boolean(error)}
                  inputId={name}
                  onBlur={onBlur}
                  onChange={(option) => {
                    if (option) {
                      setValue(name, option.value, {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      });
                      fetchYoutubeAccounts(option.value);
                    }
                  }}
                  options={Array.from(socialMediaOptions.yt.values())}
                  placeholder={commonT('selectAccount')}
                  value={(value && socialMediaOptions.yt.get(value)) || null}
                />
              </Draft.FormField>
              <InvalidNotice
                channelId={value}
                invalidStatus={socialYTState.invalidStatus}
                onCloseCallback={onCloseReset}
                tryAgainCallback={() => fetchYoutubeAccounts(currentYT.channelId)}
              />
            </Stack>
          );
        }}
      />
      <InlineToFragment>
        <Controller
          control={control}
          name="yt.privacy"
          render={({
            field: { name, onBlur, ref, value },
            fieldState: { error, invalid, isTouched },
            formState: { isSubmitted },
          }) => {
            return (
              <Draft.FormField
                dataTestId="yt-privacy-select__field"
                error={error && prefixNS('fields.privacy.required')}
                fieldLabel={prefixNS('fields.privacy.title')}
                htmlFor={name}
                touched={isTouched || isSubmitted}
              >
                <Draft.Select
                  ref={ref}
                  appendToBody
                  dataTestId={name}
                  disabled={isFetching || !socialYTState.privacyOptions}
                  hasError={invalid && Boolean(error)}
                  inputId={name}
                  name={name}
                  onBlur={onBlur}
                  onChange={(option) => {
                    if (option) {
                      setValue(name, option.value, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
                    }
                  }}
                  options={socialYTState.privacyOptions || []}
                  placeholder={commonT('selectPrivacy')}
                  value={socialYTState.privacyOptions?.find((v) => v.value === value) || null}
                />
              </Draft.FormField>
            );
          }}
        />
      </InlineToFragment>

      <HiddenSection
        dataTestId="yt-advanced-settings"
        labelWhenHidden={commonT('viewAdvancedSettings')}
        labelWhenVisible={commonT('hideAdvancedSettings')}
      >
        <Controller
          control={control}
          name="yt.webEncoderProfileId"
          render={({
            field: { name, onBlur, onChange, ref, value },
            fieldState: { isTouched },
            formState: { isSubmitted },
          }) => {
            return (
              <Draft.FormField
                fieldLabel={commonT('webPreset')}
                hint={prefixNS('fields.webPreset.tooltip')}
                hintPosition="top-end"
                htmlFor={name}
                touched={isTouched || isSubmitted}
              >
                <Draft.Select<Option>
                  ref={ref}
                  appendToBody
                  dataTestId={name}
                  onBlur={onBlur}
                  onChange={(option) => option && onChange(option.value)}
                  options={webPresetOptions}
                  placeholder={commonT('selectPreset')}
                  value={webPresetOptions.find((v) => v.value === value)}
                />
              </Draft.FormField>
            );
          }}
        />
        <Controller
          control={control}
          name="yt.title"
          render={({
            field: { name, onBlur, onChange, ref, value },
            fieldState: { error, invalid, isTouched },
            formState: { isSubmitted },
          }) => {
            const remainingCharacters = value?.length ? MAX_TITLE - value.length : MAX_TITLE;
            const maxBytesMessage = error?.type === VALIDATION.MAX_BYTES && prefixNS('fields.yt.errors.maxBytes');
            const requiredMessage = error?.type === VALIDATION.REQUIRED && prefixNS('fields.yt.errors.titleRequired');
            return (
              <Draft.FormField
                dataTestId="yt-title__field"
                error={maxBytesMessage || requiredMessage}
                fieldLabel={commonT('youtubeVideoTitle')}
                helpText={commonT('charactersRemaining', { count: remainingCharacters })}
                htmlFor={name}
                touched={isTouched || isSubmitted}
              >
                <Draft.TextInput
                  ref={ref}
                  dataTestId={name}
                  hasError={invalid && Boolean(error)}
                  id={name}
                  maxLength={MAX_TITLE}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={commonT('videoTitle')}
                  value={value || ''}
                />
              </Draft.FormField>
            );
          }}
        />
        <Controller
          control={control}
          name="yt.description"
          render={({
            field: { name, onBlur, onChange, ref, value },
            fieldState: { error, invalid, isTouched },
            formState: { isSubmitted },
          }) => {
            const remainingCharacters = value?.length ? MAX_DESCRIPTION_LENGTH - value.length : MAX_DESCRIPTION_LENGTH;
            return (
              <Draft.FormField
                dataTestId="yt-description__field"
                error={error && prefixNS('fields.yt.errors.descriptionMatch')}
                fieldLabel={commonT('youtubeVideoDescription')}
                helpText={commonT('charactersRemaining', { count: remainingCharacters })}
                htmlFor={name}
                optionalText={commonT('optional')}
                touched={isTouched || isSubmitted}
              >
                <Draft.TextInput
                  ref={ref}
                  as="textarea"
                  dataTestId={name}
                  hasError={invalid && Boolean(error)}
                  id={name}
                  maxLength={MAX_DESCRIPTION_LENGTH}
                  onBlur={onBlur}
                  onChange={onChange}
                  resize="vertical"
                  value={value || ''}
                />
              </Draft.FormField>
            );
          }}
        />
      </HiddenSection>
    </Stack>
  );
};

YoutubeFields.displayName = 'YoutubeFields';

export default YoutubeFields;
