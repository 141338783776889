import * as React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { Draft, ErrorAlt, IconButton, Inline, Link, Progress, Stack, Text } from '@resi-media/resi-ui';
import { castDraft, produce } from 'immer';
import { PLAYLIST_MAX_VIDEOS_ALLOWED } from '@studio/constants/library';
import UrlPaths from '@studio/constants/url-paths';
import { sortByAlphaIgnoreCase } from '@studio/helpers';
import { useClient, usePrefix } from '@studio/hooks';
import type { Playlists } from '@studio/types';
import { ErrorBlock } from '../ErrorBlock';

const DEFAULT_MAX_DISPLAY = 5;

type _Props = {
  maxDisplay?: number;
  onRemove?: (id: string) => void;
  playlistIds: string[];
};

const PlaylistItems = ({ maxDisplay = DEFAULT_MAX_DISPLAY, onRemove, playlistIds }: _Props): React.JSX.Element => {
  const { commonT, prefixNS } = usePrefix('pages:', 'events.contentDestinations');
  const theme = useTheme();
  const {
    callApi: getListOfPlaylistDetails,
    data,
    error,
    isFetching,
  } = useClient({
    config: useClient.mediaMetadata.v1.playlists.GET,
    query: {},
  });
  const [{ cachedPlaylistDetails, cachedPlaylistIds, viewMore }, setPlaylistItemState] = React.useState<{
    cachedPlaylistDetails: Map<string, Playlists.Get.Playlist>;
    cachedPlaylistIds: string[];
    viewMore: boolean;
  }>({
    cachedPlaylistDetails: new Map(),
    cachedPlaylistIds: [],
    viewMore: false,
  });

  const newPlaylistIds = React.useMemo(
    () => playlistIds.filter((id) => !cachedPlaylistIds.includes(id)),
    [cachedPlaylistIds, playlistIds]
  );

  React.useEffect(() => {
    if (newPlaylistIds.length > 0) {
      getListOfPlaylistDetails('', { query: { playlistIds: newPlaylistIds.join('&playlistIds=') } });
    }
  }, [getListOfPlaylistDetails, newPlaylistIds]);

  React.useEffect(() => {
    if (data) {
      setPlaylistItemState(
        produce((draft) => {
          data.forEach((playlist) => {
            draft.cachedPlaylistDetails.set(playlist.id, castDraft(playlist));
            if (!draft.cachedPlaylistIds.includes(playlist.id)) {
              draft.cachedPlaylistIds.push(playlist.id);
            }
          });
        })
      );
    }
  }, [data, playlistIds]);

  return isFetching ? (
    <Progress dataTestId="spinner" sizeVariant="s" />
  ) : (
    <Stack dataTestId="playlist-items-container" overflow="hidden" widthVariant="scale">
      <ErrorBlock error={error} />
      {Boolean(playlistIds.length) &&
        data &&
        Array.from(cachedPlaylistDetails.values())
          .filter((playlist) => playlistIds.includes(playlist.id))
          .sort(sortByAlphaIgnoreCase((playlist) => playlist.name))
          .map((playlist, index) => {
            if (index < maxDisplay || viewMore) {
              return (
                <Inline
                  key={playlist.id}
                  dataTestId="playlist-item"
                  justifyContent="space-between"
                  overflow="hidden"
                  widthVariant="scale"
                >
                  <Link
                    dataTestId="playlist-link"
                    href={UrlPaths.MEDIA.VIEW_PLAYLIST.replace(':uuid', playlist.id)}
                    rel="noopener noreferrer"
                    target="_blank"
                    truncate
                  >
                    {playlist.name}
                  </Link>
                  <Inline alignItems="center" flexShrink={0} ml={{ md: 'xs' }}>
                    {playlist.size >= PLAYLIST_MAX_VIDEOS_ALLOWED && (
                      <Draft.Tooltip content={prefixNS('fields.web.autoArchivePlaylists.full')}>
                        <ErrorAlt
                          data-testid="max-playlist-size-icon"
                          style={{
                            color: theme.palette.negative.main,
                            fontSize: theme.typography.pxToRem(18),
                          }}
                        />
                      </Draft.Tooltip>
                    )}
                    <Text colorVariant={playlist.size >= PLAYLIST_MAX_VIDEOS_ALLOWED ? 'negative' : 'primary'}>
                      {prefixNS('fields.web.autoArchivePlaylists.video', {
                        count: playlist.size,
                      }).toLowerCase()}
                    </Text>
                    {onRemove && (
                      <IconButton
                        colorVariant="negative"
                        dataTestId="playlist-remove-button"
                        label={prefixNS('removePlaylist')}
                        onClick={() => onRemove(playlist.id)}
                        sizeVariant="s"
                      >
                        <DeleteOutlined />
                      </IconButton>
                    )}
                  </Inline>
                </Inline>
              );
            } else {
              return;
            }
          })}
      {playlistIds.length > maxDisplay && (
        <Link
          dataTestId="view-more-button"
          onClick={() =>
            setPlaylistItemState(
              produce((draft) => {
                draft.viewMore = !viewMore;
              })
            )
          }
          role="button"
          weightVariant="bold"
        >
          {commonT(viewMore ? 'viewLess' : 'viewMore')}
        </Link>
      )}
    </Stack>
  );
};

PlaylistItems.displayName = 'PlaylistItems';

export default PlaylistItems;
