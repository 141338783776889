import { central } from './central';
import { cues } from './cues';
import { dataUsage } from './data-usage';
import { externals } from './externals';
import { mediaMetadata } from './media-metadata';
import { onboarding } from './onboarding';
import { playerConfig } from './player-config';
import { search } from './search';
import { status } from './status';
import { streamUsage } from './stream-usage';
import { systemEvents } from './system-events';
import { telemetry } from './telemetry';

export const config = {
  central,
  cues,
  dataUsage,
  mediaMetadata,
  onboarding,
  playerConfig,
  externals,
  search,
  status,
  streamUsage,
  systemEvents,
  telemetry,
};
