import * as React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { enableMapSet } from 'immer';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { setupInterceptors } from '@studio/services/setup-interceptors';
import store from '@studio/store';
import App from './App';
import AuthProvider from './components/AuthProvider';
import './i18n';
import {
  ldClientId,
  segmentClientId,
  LD_ANONYMOUS_CONTEXT,
  sentryEnvironment,
  sentrySampleRate,
  SENTRY_ENV_LOCAL_DEV,
} from './constants/api-constants';

if (sentryEnvironment !== SENTRY_ENV_LOCAL_DEV) {
  Sentry.init({
    beforeSend: (event, hint) => {
      const originalException = hint.originalException;
      //@ts-expect-error originalException type is unknown
      if (originalException?.response?.status !== undefined && originalException.response.status === 0) {
        return null;
      }
      return event;
    },
    dsn: 'https://8b60072bca31426dbfa7320277c0b2a4@o1151571.ingest.sentry.io/6228448',
    environment: sentryEnvironment,
    integrations: [new BrowserTracing()],
    release: import.meta.env.VITE_APP_VERSION,
    tracesSampleRate: sentrySampleRate,
    ignoreErrors: [
      'A network error occurred.',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      'timeout of 0ms exceeded',
    ],
    denyUrls: ['launchdarkly.com'],
  });
}

(async () => {
  if (window.analytics) {
    window.analytics.load(segmentClientId);
  }

  enableMapSet();

  const LDProvider = await asyncWithLDProvider({ clientSideID: ldClientId, user: { ...LD_ANONYMOUS_CONTEXT } });

  const container = document.getElementById('root');
  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <AuthProvider>
          <LDProvider>
            <App />
          </LDProvider>
        </AuthProvider>
      </Provider>
    </React.StrictMode>
  );

  // expose store during tests
  if (window.Cypress) {
    window.store = store;
  }
})();

setupInterceptors(store);
