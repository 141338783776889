import type { AxiosResponse } from 'axios';
import type { Immutable } from 'immer';
import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Schedules } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    query: {} as { endTime?: string; mediaId?: string; startTime?: string },
    response: {} as Immutable<Schedules.Get.ContentSchedule[]>,
    url: `${CENTRAL_API_V3}/customers/@customerId/schedules`,
  },
  [HTTP_METHODS.POST]: {
    method: HTTP_METHODS.POST,
    request: {} as Schedules.Post.Schedule | Schedules.Post.StartWebSchedule,
    response: {} as AxiosResponse,
    url: `${CENTRAL_API_V3}/customers/@customerId/schedules`,
  },
  [HTTP_METHODS.DELETE]: {
    method: HTTP_METHODS.DELETE,
    query: {} as { mediaId?: string },
    url: `${CENTRAL_API_V3}/customers/@customerId/schedules`,
  },
};

export const id = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    query: {} as { endTime?: string; startTime?: string },
    params: {} as { id: string },
    response: {} as Schedules.Get.ContentSchedule,
    url: `${CENTRAL_API_V3}/customers/@customerId/schedules/@id`,
  },
  [HTTP_METHODS.PUT]: {
    method: HTTP_METHODS.PUT,
    params: {} as { id: string },
    request: {} as Schedules.Post.Schedule,
    url: `${CENTRAL_API_V3}/customers/@customerId/schedules/@id`,
  },
  [HTTP_METHODS.DELETE]: {
    method: HTTP_METHODS.DELETE,
    params: {} as { id: string },
    url: `${CENTRAL_API_V3}/customers/@customerId/schedules/@id`,
  },
};
