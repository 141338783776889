import * as React from 'react';
import type { AxiosError } from 'axios';
import { produce } from 'immer';
import { createContainer } from 'react-tracked';
import { isAxiosError } from '@studio/api/api-client/is-axios-error';
import { isCancel } from '@studio/api/api-client/is-cancel';
import { asyncMap } from '@studio/helpers';
import { useClient, usePrefix } from '@studio/hooks';
import type { Playlists } from '@studio/types';
import { parsePlaylistsResponse, getHoursMinSeconds } from './helpers';

type PlaylistsContextState = {
  duration: string;
  error: AxiosError | Error | null;
  isLoading: boolean;
  mediaCount: number;
  playlists: ReadonlyMap<string, Playlists.Derived.PlaylistDetailed> | null;
};

export const initialPlaylistsState: PlaylistsContextState = {
  isLoading: false,
  mediaCount: 0,
  duration: '',
  error: null,
  playlists: null,
};

enum PLAYLISTS_ACTION_TYPES {
  SET_LOADING,
  SET_PLAYLISTS,
  SET_ERROR,
}

type PlaylistsActions =
  | {
      payload: {
        duration: string;
        mediaCount: number;
        playlists: ReadonlyMap<string, Playlists.Derived.PlaylistDetailed>;
      };
      type: PLAYLISTS_ACTION_TYPES.SET_PLAYLISTS;
    }
  | {
      payload: AxiosError | Error | null;
      type: PLAYLISTS_ACTION_TYPES.SET_ERROR;
    }
  | {
      payload: boolean;
      type: PLAYLISTS_ACTION_TYPES.SET_LOADING;
    };

const cuesReducer = (state: PlaylistsContextState, action: PlaylistsActions) => {
  switch (action.type) {
    case PLAYLISTS_ACTION_TYPES.SET_PLAYLISTS:
      state.playlists = action.payload.playlists;
      state.duration = action.payload.duration;
      state.mediaCount = action.payload.mediaCount;
      state.isLoading = false;
      break;
    case PLAYLISTS_ACTION_TYPES.SET_ERROR:
      state.error = action.payload;
      break;
    case PLAYLISTS_ACTION_TYPES.SET_LOADING:
      state.isLoading = action.payload;
      break;
  }
};

type PlaylistsContextHook = PlaylistsContextState & {
  fetchPlaylists: (v: string[]) => void;
};

const curriedReducerFunction = produce(cuesReducer);

const useValues = (): readonly [PlaylistsContextHook, () => void] => {
  const [localState, dispatch] = React.useReducer(curriedReducerFunction, {
    ...initialPlaylistsState,
  });
  const { commonT } = usePrefix('');
  const { params: getPlaylistInfo } = useClient({
    config: useClient.mediaMetadata.v1.playlists.id.media.GET,
    params: { playlistId: '' },
  });

  const fetchPlaylists = React.useCallback(
    async (playlists: string[]) => {
      dispatch({
        type: PLAYLISTS_ACTION_TYPES.SET_LOADING,
        payload: true,
      });
      const playlistInfo = await asyncMap(
        playlists,
        async (playlistId) => {
          try {
            const cachedPlaylist = localState.playlists?.get(playlistId);
            const response = cachedPlaylist
              ? cachedPlaylist
              : await getPlaylistInfo({
                  playlistId,
                }).callApi();

            return { response, id: playlistId };
          } catch (e) {
            if (isCancel(e)) return;
            console.error(e);
            if (isAxiosError(e)) {
              dispatch({
                type: PLAYLISTS_ACTION_TYPES.SET_ERROR,
                payload: e,
              });
            }
          }
        },
        4
      );

      const parsedPlaylistResponse = parsePlaylistsResponse(playlistInfo);
      const derivedTimes = getHoursMinSeconds(parsedPlaylistResponse.totalSeconds);

      const durationString = `${derivedTimes.hour}${commonT('duration.hr')} ${derivedTimes.min}${commonT(
        'duration.min'
      )}`;

      dispatch({
        type: PLAYLISTS_ACTION_TYPES.SET_PLAYLISTS,
        payload: {
          playlists: parsedPlaylistResponse.playlists,
          duration: durationString,
          mediaCount: parsedPlaylistResponse.count,
        },
      });
    },
    [commonT, getPlaylistInfo, localState.playlists]
  );

  const state = React.useMemo(
    () => ({
      ...localState,
      fetchPlaylists,
    }),
    [fetchPlaylists, localState]
  );

  return [
    state,
    () => {
      throw new Error('use functions in the state');
    },
  ];
};

const {
  Provider: PlaylistsProvider,
  useTrackedState: usePlaylists,
  useUpdate: usePlaylistsUpdate,
} = createContainer<PlaylistsContextHook, () => void, { children: React.ReactNode }>(useValues);

export { PlaylistsProvider, usePlaylists, usePlaylistsUpdate };
export type { PlaylistsContextHook };
