import * as React from 'react';
import { css, useTheme } from '@emotion/react';
import type { ResponsiveValue, Spacing } from '@resi-media/resi-ui';
import { Draft, Inline, Stack, Text, CloseAlt, sanitizeProps, useForkRef, IconButton, Hr } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';
import { responsiveInset } from '@studio/styles/global';
import { S } from './styles';

type _Props = {
  breadcrumbs?: React.ReactNode;
  canHeaderScroll?: boolean;
  children?: React.ReactNode;
  dataTestId?: string;
  description?: React.ReactNode;
  fixedHeaderGap?: ResponsiveValue<
    // eslint-disable-next-line @typescript-eslint/ban-types
    keyof Spacing | (number & {}) | (string & {})
  >;
  footerNode?: React.ReactNode;
  headerChildren?: React.ReactNode;
  hideClose?: boolean;
  hideContentScroll?: boolean;
  isBodyMaxWidth?: boolean;
  isFooterFixed?: boolean;
  isHeaderFixed?: boolean;
  onClose?: () => void;
  scrollRef?: React.ForwardedRef<HTMLDivElement>;
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
  titleButtonContainer?: React.ReactNode;
};

const FullPane = React.forwardRef<HTMLDivElement, _Props>(
  (
    {
      breadcrumbs,
      canHeaderScroll = false,
      children,
      dataTestId = 'full-pane',
      description,
      fixedHeaderGap = 'm',
      footerNode,
      headerChildren,
      hideClose,
      hideContentScroll = false,
      isBodyMaxWidth = false,
      isFooterFixed,
      isHeaderFixed,
      onClose,
      scrollRef,
      subtitle,
      title,
      titleButtonContainer,
      ...rest
    },
    ref
  ) => {
    const { commonT } = usePrefix('');
    const theme = useTheme();
    const containerRef = useForkRef(ref, scrollRef);

    const renderCloseIcon = React.useMemo(
      () => (
        <IconButton dataTestId="full-pane__close-icon" label={commonT('close')} onClick={onClose} sizeVariant="s">
          <CloseAlt
            wrapperProps={{
              customCss: S.icon(theme),
            }}
          />
        </IconButton>
      ),
      [commonT, onClose, theme]
    );

    const renderFixedHeader = React.useMemo(
      () => (
        <>
          <Stack
            alignItems="center"
            customCss={{
              container: [
                responsiveInset(theme),
                headerChildren
                  ? css`
                      & {
                        padding-bottom: 0;
                      }
                    `
                  : css``,
              ],
            }}
            dataTestId="full-pane__header--fixed"
            widthVariant="scale"
          >
            <div css={S.headerContent}>
              <Stack dataTestId="full-pane__header-children" gap={fixedHeaderGap}>
                {breadcrumbs}
                <Stack gap="xs">
                  <Inline
                    alignItems="center"
                    css={css`
                      margin-bottom: ${theme.spacing.xs};
                    `}
                    justifyContent="space-between"
                  >
                    {title &&
                      (React.isValidElement(title) ? (
                        title
                      ) : (
                        <Text as="h3" data-testid="full-pane__title--fixed">
                          {title}
                          {subtitle}
                        </Text>
                      ))}
                    <Inline alignItems="center" gap="m" justifyContent="space-between">
                      {titleButtonContainer}
                      {!hideClose && renderCloseIcon}
                    </Inline>
                  </Inline>
                  {description &&
                    (React.isValidElement(description) ? (
                      description
                    ) : (
                      <Text colorVariant="primary" dataTestId="full-pane__description--fixed" variant="body2">
                        {description}
                      </Text>
                    ))}
                </Stack>
                {headerChildren}
              </Stack>
            </div>
          </Stack>
          <Hr />
        </>
      ),
      [
        breadcrumbs,
        description,
        headerChildren,
        fixedHeaderGap,
        hideClose,
        renderCloseIcon,
        subtitle,
        theme,
        title,
        titleButtonContainer,
      ]
    );

    const renderAddHeader = React.useMemo(
      () => (
        <header
          css={css`
            padding: 0 0 ${theme.spacing.l};
            ${theme.mq.xl} {
              padding: ${title ? '2.5rem' : '0'} 0 ${theme.spacing.xl};
            }
          `}
          data-testid="full-pane__header"
        >
          <Inline
            alignItems="center"
            css={css`
              margin-bottom: ${theme.spacing.m};
            `}
            justifyContent="space-between"
          >
            {title && (
              <Text as="h3" data-testid="full-pane__title">
                {title}
              </Text>
            )}
            <Inline alignItems="center" gap="m" justifyContent="space-between">
              {titleButtonContainer}
              {!hideClose && renderCloseIcon}
            </Inline>
          </Inline>
          {description &&
            (React.isValidElement(description) ? (
              description
            ) : (
              <Text colorVariant="primary" dataTestId="full-pane__description" variant="body2">
                {description}
              </Text>
            ))}
        </header>
      ),
      [
        description,
        hideClose,
        renderCloseIcon,
        theme.mq.xl,
        theme.spacing.l,
        theme.spacing.m,
        theme.spacing.xl,
        title,
        titleButtonContainer,
      ]
    );

    return (
      <div
        ref={!canHeaderScroll ? ref : containerRef}
        css={S.container(theme, canHeaderScroll)}
        data-testid={dataTestId}
        {...sanitizeProps(rest)}
      >
        {isHeaderFixed && renderFixedHeader}
        <div
          ref={!canHeaderScroll ? scrollRef : undefined}
          css={[responsiveInset(theme), S.content(theme, canHeaderScroll, hideContentScroll)]}
          data-testid="full-pane-scrollable-area"
          id="full-pane-scrollable-area"
        >
          <div css={S.body(theme, isBodyMaxWidth)} data-testid="modal-body">
            <Draft.ModalBody
              css={css`
                overflow: visible;
              `}
              paddingVariant="none"
            >
              {!isHeaderFixed && renderAddHeader}
              {children}
              {footerNode && !isFooterFixed && (
                <Draft.ModalFooter
                  customCss={{
                    container: css`
                      padding: ${theme.typography.pxToRem(64)} 0 ${theme.typography.pxToRem(64)} 0;
                    `,
                  }}
                  dataTestId="full-pane__footer"
                  paddingVariant="none"
                  showBorder={false}
                >
                  {footerNode}
                </Draft.ModalFooter>
              )}
            </Draft.ModalBody>
          </div>
        </div>
        {footerNode && isFooterFixed && (
          <>
            <Hr />
            <Stack alignItems="center" flexFlow="column" widthVariant="scale">
              <div css={S.footerContent}>
                <Draft.ModalFooter dataTestId="full-pane__footer--fixed" showBorder={false}>
                  {footerNode}
                </Draft.ModalFooter>
              </div>
            </Stack>
          </>
        )}
      </div>
    );
  }
);

FullPane.displayName = 'FullPane';

export default FullPane;
