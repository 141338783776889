import * as React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  Draft,
  FieldMessage,
  Inline,
  Link as RUILink,
  PopoutAlt,
  Stack,
  Text,
  useToast,
  Progress,
} from '@resi-media/resi-ui';
import { produce } from 'immer';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { match, P } from 'ts-pattern';
import * as Yup from 'yup';
import { EMAIL_SUCCESS } from '@studio/constants/external-links-constants';
import { TRIAL_MODAL_BODY_STATES, VOD_TRIAL_PLAN_ID } from '@studio/constants/library';
import UrlPaths from '@studio/constants/url-paths';
import { useClient, useIsAuthorized, usePrefix } from '@studio/hooks';
import { selectCustomerName, selectUserName, selectUser } from '@studio/store';
import Permissions from '@studio/store/authentication/permissions';
import { selectCustomerMasterEcg, selectSubscriptionDetails } from '@studio/store/customer/selectors';
import { CustomerActionTypes } from '@studio/store/customer/types';
import type { Library } from '@studio/types';
import { Customer } from '@studio/types';
import NoLibraryPlanSvg from '@svg/NoLibraryPlan.svg';
import { ErrorBlock } from '../ErrorBlock';
import { ModalBodyError } from './ModalBodyError';

const initialState: Library.Components.TrialModalState = {
  modalBodyState: undefined,
  price: undefined,
  showIntro: false,
  trialEndDate: '',
  validPaymentMethodExists: false,
};

const LibraryTrialModal = () => {
  const mounted = React.useRef(false);
  const { commonT, linkT, prefixNS, PrefixTrans } = usePrefix('pages:', 'library.noPlan');
  const utils = Draft.useUtils();
  const { onCloseReset, showIntro } = Draft.ModalContext.useModal<Library.Components.TrialModalState>();
  const customerName = useSelector(selectCustomerName) ?? '';
  const currentUserName = useSelector(selectUserName) ?? '';
  const currentUser = useSelector(selectUser);
  const customerMasterEcg = useSelector(selectCustomerMasterEcg);
  const subscriptionDetails = useSelector(selectSubscriptionDetails);
  const { trigger } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const canManageSubscription = useIsAuthorized([Permissions.MANAGE_SUBSCRIPTION_UPDATE]);
  const [state, setState] = React.useState({ ...initialState, showIntro });

  const {
    callApi: getCustomer,
    data: customerData,
    isFetching: isFetchingCustomer,
  } = useClient({
    config: useClient.onboarding.v1.customers.id.GET,
  });

  const {
    callApi: getPlanDetailsSalesforce,
    data: sfPlanDetails,
    isFetching: isFetchingSfDetails,
  } = useClient({
    config: useClient.onboarding.v1.salesforce.plan.id.GET,
    params: { planId: VOD_TRIAL_PLAN_ID },
  });

  const postStartTrialSalesforce = useClient({
    config: useClient.onboarding.v1.subscriptions.salesforce.create.POST,
  });

  const isFetching = isFetchingCustomer || isFetchingSfDetails;

  const methods = useForm<{ msa: boolean }>({
    mode: 'all',
    defaultValues: {
      msa: false,
    },
    resolver: yupResolver(Yup.object({ msa: Yup.boolean().oneOf([true], commonT('errors.terms.unchecked')) })),
  });
  const { setValue } = methods;

  const getData = React.useCallback(async () => {
    if (subscriptionDetails?.contentLibraryTrialStatus === Customer.Get.TRIAL_STATUS.CANCELLED) {
      setState(
        produce((draft: Library.Components.TrialModalState) => {
          draft.modalBodyState = TRIAL_MODAL_BODY_STATES.TRIAL_EXPIRED;
        })
      );
      return;
    }
    try {
      const customer = await getCustomer();
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (customer.billingSystem !== 'SALESFORCE') {
        throw new Error('Customer not found in billing system.');
      }

      await getPlanDetailsSalesforce();
      if (!currentUser?.firstName || !currentUser.lastName) {
        setState(
          produce((draft: Library.Components.TrialModalState) => {
            draft.modalBodyState = TRIAL_MODAL_BODY_STATES.INVALID_CONTACT;
          })
        );
        return;
      }
    } catch (err) {
      setState(
        produce((draft: Library.Components.TrialModalState) => {
          draft.modalBodyState = TRIAL_MODAL_BODY_STATES.NO_PAYMENT_METHOD;
        })
      );
    }
  }, [currentUser, getCustomer, getPlanDetailsSalesforce, subscriptionDetails]);

  React.useEffect(() => {
    if (!mounted.current) {
      if (canManageSubscription) {
        getData();
      } else {
        setState(
          produce((draft: Library.Components.TrialModalState) => {
            draft.modalBodyState = TRIAL_MODAL_BODY_STATES.NOT_AUTHORIZED;
          })
        );
      }
    }

    mounted.current = true;
  }, [canManageSubscription, getCustomer, getData]);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    setState(
      produce((draft: Library.Components.TrialModalState) => {
        if (sfPlanDetails?.listPrice) {
          // price from salesforce is per month
          draft.price = sfPlanDetails.listPrice * 12;
        }
        if (sfPlanDetails?.trialLength) {
          draft.trialEndDate = utils.formatByString(utils.addDays(new Date(), sfPlanDetails.trialLength), 'MM/dd/yyyy');
        }
      })
    );
  }, [sfPlanDetails, utils]);

  React.useEffect(() => {
    setState(
      produce((draft: Library.Components.TrialModalState) => {
        if (customerData?.paymentMethod?.status === 'VALID') {
          draft.validPaymentMethodExists = true;
        } else if (customerData) {
          draft.modalBodyState = TRIAL_MODAL_BODY_STATES.NO_PAYMENT_METHOD;
        }
      })
    );
  }, [customerData]);

  const startTrial = async () => {
    await postStartTrialSalesforce.callApi({
      planId: VOD_TRIAL_PLAN_ID,
      activeUserEmailAddress: currentUserName,
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      masterEcg: customerMasterEcg,
    });

    dispatch({ type: CustomerActionTypes.FETCH_REQUEST, payload: { force: true } });
    navigate(UrlPaths.MEDIA.LIBRARY, { state: { trialStarted: true } });
    trigger({ content: prefixNS('trialStarted') });
    onCloseReset();
  };

  const renderStartTrialBody = () =>
    isFetching ? (
      <Progress dataTestId="library-trial-modal__spinner" />
    ) : (
      <Stack gap="l">
        <Draft.AlertBanner dataTestId="valid-alert-banner" variant="positive">
          <Text>{prefixNS('eligible')}</Text>
        </Draft.AlertBanner>
        <ErrorBlock error={postStartTrialSalesforce.error}></ErrorBlock>
        <Text dataTestId="library-trial-modal__access">{prefixNS('gainAccess', { name: customerName })}</Text>
        <Text>{prefixNS('startStoring')}</Text>
        <Text dataTestId="library-trial-modal__expiration">
          {prefixNS('trialWillExpire', { date: state.trialEndDate, price: state.price })}
        </Text>
      </Stack>
    );

  const renderBody = () => {
    return match({ type: state.modalBodyState, showIntro: state.showIntro })
      .with({ showIntro: true }, () => (
        <Stack dataTestId="trial-intro">
          <Text variant="h4">{prefixNS('requiresSubscription')}</Text>
          <Text>{prefixNS('accessToFeatures')}</Text>
          <Text>{prefixNS('tryFreeFor30Days')}</Text>
          <NoLibraryPlanSvg height="300" width="400" />
        </Stack>
      ))
      .with({ type: TRIAL_MODAL_BODY_STATES.NOT_AUTHORIZED }, () => (
        <ModalBodyError
          customerName={customerName}
          dataTestId="body-not-authorized"
          errorMessage={prefixNS('notAuthorized')}
        />
      ))
      .with({ type: TRIAL_MODAL_BODY_STATES.NO_PAYMENT_METHOD }, () => (
        <ModalBodyError
          customerName={customerName}
          dataTestId="body-invalid-payment"
          errorMessage={prefixNS('validPaymentMethod')}
        />
      ))
      .with({ type: TRIAL_MODAL_BODY_STATES.TRIAL_EXPIRED }, () => (
        <ModalBodyError
          customerName={customerName}
          dataTestId="body-trial-expired"
          errorMessage={prefixNS('trialExpired')}
        />
      ))
      .with({ type: TRIAL_MODAL_BODY_STATES.INVALID_CONTACT }, () => (
        <ModalBodyError
          customerName={customerName}
          dataTestId="body-invalid-contact"
          errorMessage={prefixNS('invalidContact')}
        />
      ))
      .with({ type: TRIAL_MODAL_BODY_STATES.TRIAL_SIGNUP_SUSPENDED }, () => (
        <Stack dataTestId="trials-suspended">
          <Text>
            <PrefixTrans i18nKey="contactSupport">
              fill
              <RUILink
                dataTestId="email-success-link"
                href={`${EMAIL_SUCCESS}?subject=Request to add Content Library trial.`}
                variant="body2"
              >
                fill
              </RUILink>
              {customerName}
            </PrefixTrans>
          </Text>
          <Text>{prefixNS('startStoring')}</Text>
        </Stack>
      ))
      .with({ type: undefined }, renderStartTrialBody)
      .exhaustive();
  };

  const renderFooter = () => {
    return match({ type: state.modalBodyState, showIntro: state.showIntro })
      .with({ showIntro: true }, () => (
        <Inline dataTestId="trial-intro-footer" gap="l">
          <Draft.Button dataTestId="cancel-button" label={commonT('cancel')} onClick={onCloseReset} variant="text" />
          <Draft.Button
            dataTestId="start-trial-button-intro"
            label={postStartTrialSalesforce.isFetching ? prefixNS('startingTrial') : prefixNS('startTrial')}
            onClick={() => {
              setState(
                produce((draft: Library.Components.TrialModalState) => {
                  draft.showIntro = false;
                })
              );
            }}
            {...(postStartTrialSalesforce.isFetching && {
              endNode: <Progress colorVariant="inherit" dataTestId="remove-spinner" sizeVariant="inherit" />,
            })}
          />
        </Inline>
      ))
      .with(
        {
          type: P.union(
            TRIAL_MODAL_BODY_STATES.NOT_AUTHORIZED,
            TRIAL_MODAL_BODY_STATES.NO_PAYMENT_METHOD,
            TRIAL_MODAL_BODY_STATES.TRIAL_EXPIRED,
            TRIAL_MODAL_BODY_STATES.INVALID_CONTACT,
            TRIAL_MODAL_BODY_STATES.TRIAL_SIGNUP_SUSPENDED
          ),
        },
        () => (
          <Inline gap="l">
            <Draft.Button dataTestId="close-button" label={commonT('close')} onClick={onCloseReset} variant="text" />
            <Draft.Button
              as="a"
              dataTestId="learn-more-button"
              endNode={<PopoutAlt />}
              href={linkT('libraryFeaturePage')}
              label={prefixNS('learnMore')}
              target="_blank"
            />
          </Inline>
        )
      )
      .with({ type: undefined }, () => (
        <FormProvider {...methods}>
          <form id="start-trial-form" onSubmit={methods.handleSubmit(startTrial)} style={{ width: '100%' }}>
            <Stack gap="l" widthVariant="scale">
              {!isFetching && (
                <Controller
                  name="msa"
                  render={({ field: { name, value }, fieldState: { error, isTouched } }) => (
                    <>
                      <Inline alignItems="center" gap="xs">
                        <Checkbox
                          checked={value}
                          dataTestId="msa-checkbox"
                          id={name}
                          name={name}
                          onChange={() =>
                            setValue(name, !value, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
                          }
                          value={value}
                        />
                        <Text as="label" htmlFor={name} variant="body3">
                          <PrefixTrans i18nKey="terms">
                            fill
                            <RUILink href="https://pushpay.com/legal-center/resi-legal" target="_blank" variant="body3">
                              fill
                            </RUILink>
                          </PrefixTrans>
                        </Text>
                      </Inline>
                      <FieldMessage dataTestId="msa-validation-text" error={error?.message} isVisible={isTouched} />
                    </>
                  )}
                />
              )}
              <Inline gap="l" justifyContent="flex-end">
                <Draft.Button
                  dataTestId="cancel-button"
                  label={commonT('cancel')}
                  onClick={onCloseReset}
                  variant="text"
                />
                <Draft.Button
                  dataTestId="start-trial-button"
                  disabled={
                    !state.validPaymentMethodExists || postStartTrialSalesforce.isFetching || !methods.formState.isValid
                  }
                  form="start-trial-form"
                  label={postStartTrialSalesforce.isFetching ? prefixNS('startingTrial') : prefixNS('startTrial')}
                  type="submit"
                  {...(postStartTrialSalesforce.isFetching && {
                    endNode: <Progress colorVariant="inherit" dataTestId="remove-spinner" sizeVariant="inherit" />,
                  })}
                />
              </Inline>
            </Stack>
          </form>
        </FormProvider>
      ))
      .exhaustive();
  };

  return (
    <React.Fragment>
      <Draft.ModalHeader header={prefixNS('modalTitle')} onClose={onCloseReset} showBorder />
      <Draft.ModalBody>{renderBody()}</Draft.ModalBody>
      <Draft.ModalFooter showBorder={false}>{renderFooter()}</Draft.ModalFooter>
    </React.Fragment>
  );
};

LibraryTrialModal.displayName = 'LibraryTrialModal';

export default LibraryTrialModal;
