import type { AxiosResponse } from 'axios';
import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { Customer } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V2}/customers`,
    request: {} as Customer.Post.Customer,
    response: {} as AxiosResponse,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.DELETE]: {
    url: `${CENTRAL_API_V2}/customers/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.DELETE,
  },
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/customers/@id`,
    response: {} as Customer.Get.Customer,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
};
