/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { css, useTheme } from '@emotion/react';
import type { MergeElementProps, MergeFirst } from '@resi-media/resi-ui';
import {
  isString,
  mergeDefaultProps,
  sanitizeProps,
  useBreakpoint,
  Inline,
  Stack,
  Text,
  useForkRef,
  theme,
} from '@resi-media/resi-ui';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { LegendOrdinal, LegendLabel } from '@visx/legend';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarStack } from '@visx/shape';
import type { BarGroupBar, SeriesPoint } from '@visx/shape/lib/types';
import { useTooltip, Tooltip, defaultStyles } from '@visx/tooltip';
import { COLOR_VARIANTS } from '@studio/constants/analytics';
import { convertCamelToTitleCase } from '@studio/helpers';
import { useChartColors, usePrefix } from '@studio/hooks';
import { LinearGradient } from '../LinearGradient';
import { S } from './styles';

const defaultMargin = { top: 10, left: 60, right: 0, bottom: 40 };
const Y_TICK_BREAKPOINT = 280;

type _OptionTypeBase = {
  [key: string]: any;
};

type _Props<OptionType extends _OptionTypeBase> = MergeElementProps<
  'div',
  {
    colorVariants?: string[];
    data: OptionType[];
    dataTestId?: string;
    formatTooltipData?: (tooltipData: { data: _OptionTypeBase; key: string; totals: number }) => React.ReactNode;
    height: number;
    hideSingleValueLabel?: boolean;
    isLoading?: boolean;
    labelKey?: keyof OptionType;
    leaveDelay?: number;
    legendGap?: string;
    margin?: typeof defaultMargin;
    pixelsPerSeries?: number;
    showYDecimal?: boolean;
    totalNode?: React.ReactNode;
    uniti18n?: string;
    width: number;
    xAxisTickFormat?: (val: number | string) => string;
    xTooltipNode?: string;
    yAxisTickFormat?: (val: number) => string;
  }
>;

const defaultProps = {
  colorVariants: COLOR_VARIANTS,
  hideSingleValueLabel: false,
  labelKey: 'label' as keyof _OptionTypeBase,
  leaveDelay: 100,
  legendGap: theme.spacing.xl,
  margin: defaultMargin,
  pixelsPerSeries: 100,
  showYDecimal: true,
};

type _InternalProps<OptionType extends _OptionTypeBase> = MergeFirst<_Props<OptionType>, typeof defaultProps>;

let tooltipTimeout: number;

const BarChartInternal = <OptionType extends _OptionTypeBase>(
  props: _Props<OptionType>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const propsInternal: _InternalProps<OptionType> = mergeDefaultProps(props, defaultProps);
  const {
    colorVariants,
    data: dataProp,
    dataTestId,
    formatTooltipData,
    height: heightProp,
    hideSingleValueLabel,
    isLoading,
    labelKey,
    leaveDelay,
    legendGap: legendGapProp,
    margin: marginProp,
    pixelsPerSeries,
    showYDecimal,
    totalNode: totalNodeProp,
    uniti18n,
    width,
    xAxisTickFormat,
    xTooltipNode,
    yAxisTickFormat,
    ...rest
  } = propsInternal;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const mergedRef = useForkRef(ref, containerRef);
  const containerBounds = containerRef.current?.getBoundingClientRect();
  const { commonT, prefixNS } = usePrefix('pages:', 'analytics');
  const totalNode = totalNodeProp ?? commonT('total');
  const mediaQuery = useBreakpoint();
  const theme = useTheme();
  const margin = isLoading ? { top: 32, left: 32, right: 32, bottom: 32 } : marginProp;
  const numberOfBarsToShow = Math.trunc((width - margin.left - margin.right) / pixelsPerSeries);
  const data = numberOfBarsToShow >= 1 ? dataProp.slice(0, numberOfBarsToShow) : dataProp;
  const keys = isLoading ? [] : Object.keys(data[0]).filter((d) => d !== labelKey);
  const isSingleValue = keys.length === 1 && hideSingleValueLabel;
  const totals = data.reduce<number[]>((allTotals, currentValue) => {
    const totalCount = keys.reduce((total, k) => {
      total += Number(currentValue[k]);
      return total;
    }, 0);
    allTotals.push(totalCount);
    return allTotals;
  }, []);

  const { hideTooltip, showTooltip, tooltipData, tooltipLeft, tooltipOpen, tooltipTop } = useTooltip<{
    data: OptionType;
    key: string;
    totals: number;
  }>();
  const getXAxisValue = React.useCallback((d: OptionType) => d[labelKey], [labelKey]);
  const maxValue = Math.max(...totals);
  const getMaxTicks = () => {
    if (heightProp <= Y_TICK_BREAKPOINT) {
      return showYDecimal ? 5 : Math.min(5, maxValue);
    } else if (maxValue >= 1 && maxValue < 9) {
      return maxValue;
    }
    return 9;
  };
  const maxTicks = getMaxTicks();
  const isCompactNumberFormat = maxValue >= 10000;
  const datum = tooltipData?.data;
  const sortedTooltips = datum
    ? Object.keys(datum)
        .filter((e) => e !== labelKey)
        .sort((a, b) => datum[b] - datum[a])
    : [];

  const numberOfDataSeries = data.length ? Object.keys(data[0]).filter((e) => e !== labelKey) : [];
  const isWrapped = window.innerWidth / 2 - 8 > 256 && numberOfDataSeries.length > 5;
  const wrappedTooltipWidth = mediaQuery.lg ? 320 : 256;
  const tooltipWidth = isWrapped ? wrappedTooltipWidth : 160;
  const tooltipBeyondWindow = Math.min(
    0,
    window.innerWidth - ((tooltipLeft ?? 0) + tooltipWidth / 2 + (containerBounds?.left ?? 0) + S.tooltipHypot / 2)
  );

  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: theme.palette.background.tooltip,
    color: theme.palette.background.default,
  };

  // scales, memoize for performance
  const colorScale = scaleOrdinal<keyof OptionType, string>({
    domain: keys,
    range: useChartColors(keys),
  });

  const handleMouseLeave = () => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, leaveDelay);
  };

  const handleMouseMove = (
    _event: React.MouseEvent,
    bar: Omit<BarGroupBar<string>, 'key' | 'value'> & {
      bar: SeriesPoint<OptionType>;
      key: string;
    },
    sizing: {
      top: number;
    }
  ) => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout);
    const deltaFromTop = sizing.top;
    const left = bar.x + margin.left + bar.width / 2 - S.tooltipArrowSize;
    const top = heightProp - margin.top - deltaFromTop + S.tooltipHypot / 2;

    showTooltip({
      tooltipData: { data: data[bar.index], key: bar.key, totals: totals[bar.index] },
      tooltipTop: top,
      tooltipLeft: left,
    });
  };

  return width < 10 ? null : (
    <div ref={mergedRef} css={S.container(theme, propsInternal)} data-testid={dataTestId} {...sanitizeProps(rest)}>
      <div
        css={css`
          position: relative;
          flex: 0 1 100%;
          overflow: hidden;
        `}
      >
        <ParentSize>
          {(size) => {
            const height = mediaQuery.lg ? size.height : 240;
            const xMax = width - margin.left - margin.right;
            const yMax = size.height - margin.top - margin.bottom;
            const xScale = scaleBand<number | string>({
              range: [0, xMax],
              round: true,
              domain: isLoading ? [1, 2, 3, 4] : data.map(getXAxisValue),
              padding: 0.5,
            });

            const yScale = scaleLinear<number>({
              range: [yMax, 0],
              round: true,
              domain: [0, isLoading ? 5 : maxValue],
            });

            return (
              <svg height={height} width={width}>
                <LinearGradient id="bar-chart-loading" />
                {!isLoading && (
                  <GridRows
                    height={yMax}
                    left={margin.left}
                    numTicks={maxTicks}
                    scale={yScale}
                    stroke={theme.palette.divider}
                    top={margin.top}
                    width={xMax}
                  />
                )}
                <Group left={margin.left} top={margin.top}>
                  {isLoading ? (
                    <g>
                      <defs>
                        <clipPath id="bar-chart-clip-path">
                          {[1, 2, 3, 4].map((num) => (
                            <rect
                              key={`bar-stack-loading-${num}`}
                              data-testid={`bar-stack-loading-${num}`}
                              height={Math.max(0, yMax - (yScale(num) || 0))}
                              rx={4}
                              width={xScale.bandwidth()}
                              x={(xScale(num) || 0) + 8}
                              y={yScale(num) - 10}
                            />
                          ))}
                          <rect height={Math.max(0, yMax)} width="1" x={8} />
                          <rect height="1" width={xMax} x={0} y={yMax - 8} />
                        </clipPath>
                      </defs>
                      <rect
                        clipPath="url(#bar-chart-clip-path)"
                        fill="url(#bar-chart-loading)"
                        height={Math.max(0, yMax)}
                        width={xMax}
                        x={0}
                        y={0}
                      />
                    </g>
                  ) : (
                    <>
                      <BarStack
                        color={colorScale}
                        data={data}
                        keys={keys}
                        x={getXAxisValue}
                        xScale={xScale}
                        yScale={yScale}
                      >
                        {(barStacks) => {
                          /** Start with an array of null values matching the # of bars  */
                          const bars = [...Array(data.length).keys()].map(() => null);
                          /** Reverse BarStacks so can find first instance of data with value  */
                          const barStacksReversed = barStacks.slice().reverse();
                          const lastIndexWithData = bars.reduce((agg: null[] | number[], _bs, i) => {
                            /* Don't continue reduce if value is already present */
                            if (agg[i] === null) {
                              const newValue = barStacksReversed.reduce(
                                (aggInner: { index: number } | null, barStack) => {
                                  if (!aggInner && barStack.bars[i].height !== 0) {
                                    aggInner = {
                                      index: barStack.index,
                                    };
                                  }
                                  return aggInner;
                                },
                                null
                              );
                              agg[i] = newValue?.index ?? data.length - 1;
                            }
                            return agg;
                          }, bars);

                          return barStacks.map((barStack, i) => {
                            return barStack.bars.map((bar, j) => {
                              const yScaleSanitized = isNaN(yScale(0)) ? 0 : yScale(0);
                              // Prevent radius from being larger than height based on value
                              const r = Math.max(0, Math.min(4, yScaleSanitized - bar.y));
                              const lastBarStack = barStacks.length - 1 === i;

                              return (
                                <svg key={j}>
                                  {/* Hidden bar provides improved onHover UX */}
                                  {lastBarStack && (
                                    <g transform={`translate(0, ${-yMax})`}>
                                      <rect
                                        key={`hidden-bar-stack-${barStack.index}-${bar.index}`}
                                        data-testid={`hidden-bar-stack-${barStack.index}-${bar.index}`}
                                        fillOpacity="0.0"
                                        height={Math.max(0, yMax)}
                                        onMouseLeave={handleMouseLeave}
                                        onMouseMove={(event) =>
                                          handleMouseMove(event, bar, { top: yScale(totals[bar.index]) })
                                        }
                                        width={bar.width}
                                        x={bar.x}
                                        y={bar.y}
                                      />
                                    </g>
                                  )}
                                  {barStack.index === lastIndexWithData[j] ? (
                                    <path
                                      key={`bar-stack-${barStack.index}-${bar.index}`}
                                      d={
                                        /**
                                            Move to bottom left corner below radius
                                            `M${bar.x}, ${bar.y + r}`,
                                            Arc to top of top left arc from current point
                                            `a${r},${r} 0 0 1 ${r},-${r}`,
                                            Horizontal Top Line from current point
                                            `h${bar.width - 2 * r}`,
                                            Arc to bottom of top right arc from current point
                                            `a${r}, ${r} 0 0 1 ${r}, ${r}`,
                                            Vertical Line from current point
                                            `v${bar.height - r}`,
                                            Draw horizontal line to current endpoint
                                            `h-${bar.width}z`,
                                           **/
                                        [
                                          `M${bar.x} ${bar.y + r} a${r} ${r} 0 0 1 ${r} -${r} h${
                                            bar.width - 2 * r
                                          } a${r} ${r} 0 0 1 ${r} ${r} v${bar.height - r} h-${bar.width}z`,
                                        ].join()
                                      }
                                      data-testid={`last-bar-stack-${barStack.index}-${bar.index}`}
                                      fill={bar.color}
                                      onMouseLeave={handleMouseLeave}
                                      onMouseMove={(event) =>
                                        handleMouseMove(event, bar, {
                                          top: yScale(totals[bar.index]),
                                        })
                                      }
                                    />
                                  ) : (
                                    <rect
                                      key={`bar-stack-${barStack.index}-${bar.index}`}
                                      data-testid={`bar-stack-${barStack.index}-${bar.index}`}
                                      fill={bar.color}
                                      height={Math.max(0, bar.height)}
                                      onMouseLeave={handleMouseLeave}
                                      onMouseMove={(event) =>
                                        handleMouseMove(event, bar, { top: yScale(totals[bar.index]) })
                                      }
                                      width={bar.width}
                                      x={bar.x}
                                      y={bar.y}
                                    />
                                  )}
                                </svg>
                              );
                            });
                          });
                        }}
                      </BarStack>
                      <AxisLeft
                        numTicks={maxTicks}
                        scale={yScale}
                        stroke={theme.palette.divider}
                        tickFormat={(value) => {
                          const isFractionalFormat = maxValue <= 1;
                          if (yAxisTickFormat?.(Number(value))) {
                            return yAxisTickFormat(Number(value));
                          } else if (isCompactNumberFormat) {
                            const compactStr = Intl.NumberFormat('en', {
                              notation: 'compact',
                              maximumFractionDigits: 2,
                            });
                            return compactStr.format(value as number);
                          } else if (isFractionalFormat && showYDecimal) {
                            if (value === 0) {
                              return `${value}`;
                            }
                            return `${Number(value).toFixed(2)}`;
                          } else {
                            return `${Math.trunc(Number(value))}`;
                          }
                        }}
                        tickLabelProps={() => ({
                          'data-testid': 'y-axis-tick',
                          dx: `-8px`,
                          fontFamily: theme.typography.fontFamilyBody,
                          fontSize: theme.typography.body3.fontSize,
                          lineHeight: 1,
                          verticalAnchor: 'middle',
                          textAnchor: 'end' as const,
                          fill: theme.palette.text.primary,
                        })}
                        tickStroke={theme.palette.divider}
                      />
                      <AxisBottom
                        hideTicks
                        scale={xScale}
                        stroke={theme.palette.divider}
                        tickComponent={({ formattedValue, ...tickProps }) => (
                          <svg data-testid="x-axis-tick">
                            <text {...tickProps}>
                              <tspan>{formattedValue?.split(',')?.[0]}</tspan>
                              <title>{formattedValue}</title>
                            </text>
                          </svg>
                        )}
                        tickLabelProps={() => ({
                          fill: theme.palette.text.primary,
                          fontFamily: theme.typography.fontFamilyBody,
                          fontSize: theme.typography.body3.fontSize,
                          textAnchor: 'middle',
                        })}
                        tickStroke={theme.palette.divider}
                        top={yMax}
                        {...(xAxisTickFormat && {
                          tickFormat: (d) => xAxisTickFormat(d),
                        })}
                      />
                    </>
                  )}
                </Group>
              </svg>
            );
          }}
        </ParentSize>
      </div>
      {tooltipOpen && tooltipData && (
        <Tooltip
          css={S.arrow}
          style={{
            ...tooltipStyles,
            top: 'auto',
            left: tooltipLeft,
            bottom: tooltipTop,
          }}
        >
          <div css={S.tooltip(theme, tooltipBeyondWindow, isWrapped)} data-testid="visx-tooltip">
            <Stack gap="m">
              <Stack gap="unset">
                {uniti18n && (
                  <Text colorVariant="inherit" variant="body4" weightVariant="bold">
                    {prefixNS(uniti18n)}
                  </Text>
                )}
                <Text colorVariant="inherit" variant="body4" weightVariant="bold">
                  {tooltipData.data[labelKey]}
                </Text>
                {xTooltipNode && (
                  <Text colorVariant="inherit" variant="body4">
                    {xTooltipNode}
                  </Text>
                )}
              </Stack>
              {isSingleValue &&
                sortedTooltips.map((key) => {
                  const viewers = tooltipData.data[key];
                  const formattedViewers = isCompactNumberFormat
                    ? Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 2 }).format(viewers)
                    : viewers;

                  return (
                    key !== labelKey && (
                      <Text key={key} as="div" colorVariant="inherit" dataTestId="single-value-tooltip" variant="body4">
                        <Inline key={key} alignItems="flex-end" gap="xs">
                          <span>
                            {formatTooltipData ? formatTooltipData(tooltipData) : formattedViewers.toLocaleString()}{' '}
                          </span>
                        </Inline>
                      </Text>
                    )
                  );
                })}
              {!isSingleValue && (
                <Stack
                  gap="unset"
                  style={{
                    justifyContent: 'center',
                    alignItems: 'start',
                    display: 'grid',
                    gridAutoFlow: 'column',
                    gridTemplateColumns: `repeat(${isWrapped ? 2 : 1}, 1fr)`,
                    // NOTE: make each grid item's height equal to 3 lines of text to support truncated labels
                    gridTemplateRows: `repeat(${isWrapped ? 5 : sortedTooltips.length},
                      fit-content(calc(3 * ${theme.typography.body4.lineHeight} * ${
                        theme.typography.body4.fontSize
                      })))`,
                    columnGap: theme.spacing.m,
                    rowGap: theme.spacing.s,
                  }}
                >
                  {sortedTooltips.map((key) => {
                    const viewers = tooltipData.data[key];
                    const formattedViewers = isCompactNumberFormat
                      ? Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 2 }).format(viewers)
                      : viewers;

                    return (
                      key !== labelKey && (
                        <div key={key}>
                          <Inline alignItems="center" gap="xs">
                            <svg height={12} style={{ flexShrink: 0 }} width={12}>
                              <circle
                                cx={6}
                                cy={6}
                                fill={colorScale(key)}
                                r={5.5}
                                stroke={theme.palette.common.white}
                                strokeWidth="1"
                              />
                            </svg>
                            {isString(key) && (
                              <Text colorVariant="inherit" css={S.tooltipLabel} variant="body4" weightVariant="bold">
                                {convertCamelToTitleCase(key)}
                              </Text>
                            )}
                          </Inline>
                          <Text colorVariant="inherit" variant="body4">
                            {formattedViewers.toLocaleString()}
                          </Text>
                        </div>
                      )
                    );
                  })}
                </Stack>
              )}
              {keys.length > 1 && (
                <div>
                  {isString(totalNode) ? (
                    <Text as="div" colorVariant="inherit" variant="body4" weightVariant="bold">
                      {totalNode}
                    </Text>
                  ) : (
                    totalNode
                  )}
                  <Text as="div" colorVariant="inherit" variant="body4">
                    {(isCompactNumberFormat
                      ? Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 2 }).format(
                          tooltipData.totals
                        )
                      : tooltipData.totals
                    ).toLocaleString()}
                  </Text>
                </div>
              )}
            </Stack>
          </div>
        </Tooltip>
      )}
      {!isLoading && !isSingleValue && (
        <LegendOrdinal direction="column" scale={colorScale}>
          {(labels) => (
            <Inline
              flexWrap="wrap"
              gap={`${theme.spacing.l} ${theme.spacing.xl}`}
              style={{ paddingLeft: '4rem' }}
              widthVariant="scale"
            >
              {labels.map((label, i) => {
                const color = colorScale(label.datum);
                return (
                  <Inline key={`legend-quantile-${i}`} alignItems="center">
                    <svg height={12} width={12}>
                      <circle cx={6} cy={6} fill={color} r={6} />
                    </svg>
                    <LegendLabel align="left" margin="0 0 0 4px">
                      <Text dataTestId="bar-chart-label" truncate variant="body2">
                        {convertCamelToTitleCase(label.text)}
                      </Text>
                    </LegendLabel>
                  </Inline>
                );
              })}
            </Inline>
          )}
        </LegendOrdinal>
      )}
    </div>
  );
};

BarChartInternal.displayName = 'BarChart';

/* eslint-disable import/export */
export const BarChart = React.forwardRef(BarChartInternal) as <T extends _OptionTypeBase>(
  props: _Props<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof BarChartInternal>;

// eslint-disable-next-line no-redeclare
export namespace BarChart {
  export type InternalProps = _InternalProps<_OptionTypeBase>;
  export type OptionTypeBase = _OptionTypeBase;
  export type Props = _Props<_OptionTypeBase>;
}
