import * as React from 'react';
import { DownAlt, Inline, Link, Stack, UpAlt } from '@resi-media/resi-ui';

type _Props = {
  children: React.ReactNode;
  dataTestId?: string;
  labelWhenHidden: string;
  labelWhenVisible: string;
};
const HiddenSection = ({ children, dataTestId, labelWhenHidden, labelWhenVisible }: _Props): React.JSX.Element => {
  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <Stack dataTestId={dataTestId} gap="l">
      <Link<'button'>
        data-testid="show-hide-button"
        onClick={() => setIsVisible(!isVisible)}
        role="button"
        style={{ maxWidth: '235px' }}
      >
        <Inline alignItems="center" dataTestId="link-label" gap="m">
          {isVisible ? labelWhenVisible : labelWhenHidden}
          {isVisible ? <UpAlt /> : <DownAlt />}
        </Inline>
      </Link>
      {isVisible && children}
    </Stack>
  );
};

HiddenSection.displayName = 'HiddenSection';

export default HiddenSection;
