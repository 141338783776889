import * as React from 'react';
import { ClusterOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import type { PaletteMode } from '@resi-media/resi-ui';
import {
  Badge,
  DarkMode,
  Draft,
  Inline,
  List,
  ListItem,
  Stack,
  Text,
  ToggleInput,
  useThemeConfig,
} from '@resi-media/resi-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import { LAUNCHDARKLY_TOGGLES } from '@studio/constants/launchdarkly-toggle-constants';
import UrlPaths from '@studio/constants/url-paths';
import NavContext, { NAV_ACTION } from '@studio/contexts/nav';
import { useKeyDown, usePrefix, useSignOut } from '@studio/hooks';
import { useTypedSelector } from '@studio/store';
import Permissions from '@studio/store/authentication/permissions';
import { Authorized } from '../Authorized';
import { PopupMenu } from '../PopupMenu';
import { S } from './styles';

type _Props = {
  shouldCloseProfilePopup: boolean;
};

const ProfilePopup = ({ shouldCloseProfilePopup }: _Props) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { setMode } = useThemeConfig();
  const open = Boolean(anchorEl);
  const prevOpen = React.useRef(open);
  const { [LAUNCHDARKLY_TOGGLES.ACCT_SETTINGS_REDESIGN]: acctSettingRedesign } = useFlags();
  const handleSignOutClick = useSignOut();
  const { dispatch } = React.useContext(NavContext);
  const { commonT, prefixNS } = usePrefix('navigation:');
  const { user } = useTypedSelector((state) => state.authentication);

  const modifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 8],
      },
    },
  ];

  const handleClick = (
    event: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    !open && dispatch({ type: NAV_ACTION.DISPLAY_MOBILE_NAV, payload: false });
    if (event.currentTarget === anchorRef.current) {
      event.stopPropagation();
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const themeMode = localStorage.getItem('studio-theme-mode') as PaletteMode | null;
  const setDarkMode = React.useCallback(() => {
    const themeToSet = themeMode === 'dark' ? 'light' : 'dark';
    setMode(themeToSet);
    localStorage.setItem('studio-theme-mode', themeToSet);
  }, [setMode, themeMode]);

  const handleKeyDown = useKeyDown(['Enter', ' '], handleClick);
  const handleSignOutKeyDown = useKeyDown(['Enter', ' '], handleSignOutClick);

  React.useEffect(() => {
    if (prevOpen.current && !open && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    //ensures the persisting ref is reset to null
    if (shouldCloseProfilePopup) {
      setAnchorEl(null);
    }
  }, [shouldCloseProfilePopup]);

  return (
    <>
      <Draft.Button
        ref={anchorRef}
        css={S.profileButton}
        customCss={{
          container: css`
            max-width: 232px;
          `,
          children: css`
            overflow: hidden;
          `,
        }}
        data-testid="profile-button"
        endNode={<img alt="profile" css={S.profileAvatar} src={user?.userImage} />}
        label={(user?.customerName ?? '').split('-')[0]}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        px="s"
        py="xs"
        variant="outlined"
      >
        <Text colorVariant="inherit" truncate variant="inherit">
          {(user?.customerName ?? '').split('-')[0]}
        </Text>
      </Draft.Button>
      <PopupMenu
        anchorEl={anchorEl}
        closeOnClick
        data-testid="profile-popup__menu"
        fullWidthMobile
        isOpen={open}
        modifiers={modifiers}
        onClose={() => setAnchorEl(null)}
      >
        <div css={S.accountPopUpOptionWrap}>
          <Inline
            alignItems="center"
            css={(theme: Theme) => css`
              ${theme.mixins.inset('m')(theme)}
            `}
            dataTestId="menu-header"
            gap="m"
          >
            <span css={S.profilePhoto}>
              <img alt="profile" src={user?.userImage} />
            </span>
            <Stack gap="xs">
              <Text colorVariant="heading" variant="h5">
                {user?.customerName}
              </Text>
              <Text colorVariant="disabled" variant="body6">
                {user?.userName}
              </Text>
            </Stack>
          </Inline>
          {user && Boolean(user.actualCustomer) && (
            // The `actualCustomer` value is ONLY generated within the api response, when a User is outside of their home org
            <Stack css={S.overrideBadge} dataTestId="non-home-org-badge">
              <Badge
                colorVariant="red"
                customCss={{
                  container: css`
                    min-width: 288px;
                    min-height: 60px;
                  `,
                }}
              >
                <Stack>
                  <Text colorVariant="inherit" variant="body4">
                    {prefixNS('currentOrgOverride')}
                  </Text>
                  <Text as="h5" colorVariant="inherit" dataTestId="profile-popup__customer">
                    {user.customerName}
                  </Text>
                </Stack>
              </Badge>
            </Stack>
          )}
          <Stack css={S.detailsWrap}>
            <List
              customCss={{
                container: css`
                  box-shadow: none;
                  border: none;
                `,
              }}
              index={-1}
              orientation="vertical"
              sizeVariant="m"
            >
              <ListItem
                as={Link}
                dataTestId="account-details"
                startNode={<UserOutlined />}
                to={UrlPaths.SETTINGS.ACCOUNT}
              >
                <Inline gap="m">{prefixNS('accountDetails')}</Inline>
              </ListItem>
              <Authorized requiredPerms={[Permissions.ORGANIZATION_DETAILS_GET]}>
                <ListItem
                  as={Link}
                  dataTestId="organization-details"
                  startNode={<ClusterOutlined />}
                  to={UrlPaths.SETTINGS.ORGANIZATION_DETAILS}
                >
                  {prefixNS('organizationDetails')}
                </ListItem>
              </Authorized>
              {/* Remove dark mode menu when ACCT_SETTINGS_REDESIGN LD toggle is no longer needed */}
              {!acctSettingRedesign && (
                <ListItem dataTestId="profile-popup__toggle--mode" onClick={setDarkMode} startNode={<DarkMode />}>
                  <Inline alignItems="center" justifyContent="space-between">
                    {prefixNS('darkMode')}
                    <ToggleInput checked={themeMode === 'dark'} readOnly tabIndex={-1} />
                  </Inline>
                </ListItem>
              )}
              <div css={S.signOutWrap}>
                <ListItem
                  colorVariant="negative"
                  dataTestId="sign-out"
                  onClick={handleSignOutClick}
                  onKeyDown={handleSignOutKeyDown}
                  startNode={<LogoutOutlined />}
                >
                  {commonT('signOut')}
                </ListItem>
              </div>
            </List>
          </Stack>
        </div>
      </PopupMenu>
    </>
  );
};

ProfilePopup.displayName = 'ProfilePopup';

export default ProfilePopup;
