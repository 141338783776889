import * as React from 'react';
import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import { Box, Draft, Progress, usePrevious } from '@resi-media/resi-ui';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePrefix } from '@studio/hooks';

const S = {
  loadingContainer: (theme: Theme): SerializedStyles => css`
    text-align: center;
    padding-bottom: ${theme.spacing.xl};
  `,
  errorContainer: (_theme: Theme): SerializedStyles => css`
    margin: 0 auto 3rem auto;
    max-width: 780px;
  `,
};

interface InfiniteScrollFullListProps<T> {
  error?: Error | null;
  errorContainerProps?: Draft.AlertBanner.Props;
  items: T[];
  loadingContainerProps?: Box.Props;
  renderList: (visibleList: T[]) => React.ReactNode;
  scrollableTarget: string;
}

const initialCount = {
  prev: 0,
  next: 50,
};

// This infinite scroll component can be used when the full list of items is already loaded,
// and we just want to give the illusion that the items are being loaded in pages.
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const InfiniteScrollFullList = <T extends unknown>({
  error,
  errorContainerProps = {},
  items,
  loadingContainerProps = {},
  renderList,
  scrollableTarget,
}: InfiniteScrollFullListProps<T>) => {
  const { commonT } = usePrefix('');
  const [count, setCount] = React.useState(initialCount);
  const [hasMore, setHasMore] = React.useState(true);
  const [current, setCurrent] = React.useState<T[]>([]);
  const previousItems = usePrevious(items);

  React.useEffect(() => {
    if (previousItems !== items) {
      setCurrent(items.slice(initialCount.prev, initialCount.next));
      setCount(initialCount);
      if (items.length > initialCount.next) {
        setHasMore(true);
      }
    } else if (current.length === 0) {
      setCurrent(items.slice(initialCount.prev, initialCount.next));
    } else {
      // if items are already loaded, then ensure the same number of items remain visible
      setCurrent(items.slice(0, current.length));
    }
  }, [current.length, items, previousItems]);

  const getMoreData = () => {
    const isMoreData = doesHaveMoreData(current);
    if (isMoreData) {
      setTimeout(() => {
        setCurrent(current.concat(items.slice(count.prev + 50, count.next + 50)));
      }, 500);
    }
  };

  const doesHaveMoreData = (currentList: T[]) => {
    if (currentList.length >= items.length) {
      setHasMore(false);
      return false;
    }

    setCount((prevState) => ({
      prev: prevState.prev + 50,
      next: prevState.next + 50,
    }));

    return true;
  };

  return (
    <div data-testid="infinite-scroll-full-list">
      <InfiniteScroll
        dataLength={current.length}
        hasMore={hasMore}
        loader={
          error ? null : (
            <Box css={S.loadingContainer} {...loadingContainerProps}>
              <Progress sizeVariant="m" />
            </Box>
          )
        }
        next={getMoreData}
        scrollableTarget={scrollableTarget}
        style={{ overflow: 'none' }}
      >
        {renderList(current)}
        {error && (
          <Draft.AlertBanner css={S.errorContainer} {...errorContainerProps}>
            {commonT('errors.genericTryAgain')}
          </Draft.AlertBanner>
        )}
      </InfiniteScroll>
    </div>
  );
};

InfiniteScrollFullList.displayName = 'InfiniteScrollFullList';

export default InfiniteScrollFullList;
