import * as duration from 'duration-fns';
import type { MapReturn } from '@studio/helpers';
import type { Playlists } from '@studio/types';

function parsePlaylistsResponse(playlistInfo: MapReturn<{ id: string; response: Playlists.Derived.PlaylistDetailed }>) {
  return playlistInfo.reduce(
    (agg, playlist) => {
      if (!playlist.value) return agg;
      const durationString = playlist.value.response.totalLength;

      return {
        count: (agg.count += playlist.value.response.mediaCount),
        totalSeconds: (agg.totalSeconds += duration.toSeconds(durationString)),
        playlists: agg.playlists.set(playlist.value.id, playlist.value.response),
      };
    },
    {
      count: 0,
      totalSeconds: 0,
      playlists: new Map() as Map<string, Playlists.Derived.PlaylistDetailed>,
    }
  );
}

export default parsePlaylistsResponse;
