import { css, useTheme } from '@emotion/react';
import { Draft, Link, Stack, Text } from '@resi-media/resi-ui';
import type { FallbackRender } from '@sentry/react/types/errorboundary';
import { usePrefix } from '@studio/hooks';
import AppError from '@svg/AppError.svg';
import { RerouteInfo } from '../RerouteInfo';

type ResetError = Parameters<FallbackRender>[0]['resetError'];

type _Props = {
  resetError: ResetError;
};

const ErrorBoundaryFallback = ({ resetError }: _Props) => {
  const { commonT, linkT, prefixNS, PrefixTrans } = usePrefix('components:', 'errorBoundary');
  const theme = useTheme();
  return (
    <RerouteInfo
      contentHeading={commonT('unexpectedErrorOccurred')}
      ctaButton={<Draft.Button label={commonT('retry')} onClick={resetError} variant="outlined" />}
      header={
        <Stack
          alignItems="center"
          css={css`
            min-height: ${theme.typography.pxToRem(205)};
            background-color: ${theme.palette.primary.active};
          `}
          justifyContent="center"
        >
          <Text
            colorVariant="inverted"
            css={css`
              font-size: ${theme.typography.pxToRem(80)};
            `}
            variant="h1"
          >
            {commonT('oops')}
          </Text>
        </Stack>
      }
      paragraph1={prefixNS('refreshToTryAgain')}
      paragraph2={
        <PrefixTrans i18nKey="contactIfPersists">
          fill
          <Link href={linkT('contact')} rel="noopener noreferrer" target="_blank" variant="body2">
            contactSupport
          </Link>
        </PrefixTrans>
      }
      svg={<AppError />}
    />
  );
};

ErrorBoundaryFallback.displayName = 'ErrorBoundaryFallback';
export default ErrorBoundaryFallback;
